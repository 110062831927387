import React, { useState } from "react";

import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Offcanvas, Spinner, Tab, Tabs } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { useCreateFacility } from "features/entity/services";
import { entityValidationSchema } from "features/entity/schemas";

import GeneralInfomationFields from "./GeneralInfomationFields";
import LocationAndContactFields from "./LocationAndContactFields";
import AdvancedInfomationFields from "./AdvancedInfomationFields";

const FacilityCreate = ({ onFacilityCreated }) => {
  const { isAdmin } = useRoles();
  const [show, setShow] = useState();
  const [step, setStep] = useState("step-1");

  const { error, isLoading, createFacility } = useCreateFacility({
    onFacilityCreated: (data) => {
      handleClose();
      onFacilityCreated(data);
    },
  });

  const handleShow = () => {
    setShow(true);
    setStep("step-1");
  };
  const handleClose = () => setShow(false);

  if (!isAdmin) return <></>;
  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        <span translate="no" className="material-symbols-outlined md-18">
          add
        </span>{" "}
        New Entity
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="w-fixed-640"
        placement="end"
        scroll
      >
        <Formik
          validationSchema={entityValidationSchema}
          onSubmit={(values) => {
            createFacility({
              //General Information
              name: values.name,
              address: values.address,
              custom_id: values.customId,
              description: values.description,
              labels: values.entityTags.map(
                ({ organizationLabelId: id }) => id
              ),

              //Location & Contact Information
              county: values.county,
              latitude: values.latitude,
              longitude: values.longitude,
              mailing_address: values.mailingAddress,
              facility_phone_number: values.entityPhoneNumber,
              Facility_24_hr_phone_number: values.entity24hrPhoneNumber,

              //Advanced Information
              lepc: values.lepc,
              naics_code: values.naicsCode,
              facility_type: values.entityType,
              max_occupancy: values.maxOccupancy,
              fire_department: values.fireDepartment,
              number_of_employees: values.numberOfEmployees,
              dun_and_bradstreet_number: values.dunAndBradstreetNumber,
              facility_supplies_to:
                values.facilitySuppliesTo[0]?.facilityId ?? "",
              facility_supplies_from:
                values.facilitySuppliesFrom[0]?.facilityId ?? "",
            });
          }}
          initialValues={{
            //General Information
            name: "",
            address: "",
            customId: "",
            entityTags: [],
            description: "",

            //Contact / Location Information
            county: "",
            latitude: "",
            longitude: "",
            mailingAddress: "",
            entityPhoneNumber: "",
            entity24hrPhoneNumber: "",

            //Advanced Information
            lepc: "",
            naicsCode: "",
            entityType: "",
            maxOccupancy: "",
            fireDepartment: "",
            numberOfEmployees: "",
            dunAndBradstreetNumber: "",
            facilitySuppliesTo: [],
            facilitySuppliesFrom: [],
          }}
        >
          {({ values, errors, touched, isValid, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="overflow-scroll">
              <Offcanvas.Header className="border-bottom" closeButton>
                <Offcanvas.Title>New Entity</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="vh-100">
                <div>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={step}
                    onSelect={(k) => setStep(k)}
                    className="mb-3"
                  >
                    <Tab
                      eventKey="step-1"
                      title={
                        <>
                          General Information{" "}
                          {((errors.name && touched.name) ||
                            (errors.address && touched.address) ||
                            (errors.customId && touched.customId) ||
                            (errors.description && touched.description) ||
                            (errors.entityTags && touched.entityTags)) && (
                            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                          )}
                        </>
                      }
                    >
                      <GeneralInfomationFields />
                    </Tab>
                    <Tab
                      eventKey="step-2"
                      title={
                        <>
                          Location & Contact Info{" "}
                          {((errors.county && touched.county) ||
                            (errors.latitude && touched.latitude) ||
                            (errors.longitude && touched.longitude) ||
                            (errors.mailingAddress && touched.mailingAddress) ||
                            (errors.entityPhoneNumber &&
                              touched.entityPhoneNumber) ||
                            (errors.entity24hrPhoneNumber &&
                              touched.entity24hrPhoneNumber)) && (
                            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                          )}
                        </>
                      }
                    >
                      <LocationAndContactFields />
                    </Tab>
                    <Tab
                      eventKey="step-3"
                      title={
                        <>
                          Advanced Information
                          {((errors.maxOccupancy && touched.maxOccupancy) ||
                            (errors.entityType && touched.entityType) ||
                            (errors.numberOfEmployees &&
                              touched.numberOfEmployees) ||
                            (errors.fireDepartment && touched.fireDepartment) ||
                            (errors.lepc && touched.lepc) ||
                            (errors.dunAndBradstreetNumber &&
                              touched.dunAndBradstreetNumber) ||
                            (errors.naicsCode && touched.naicsCode) ||
                            (errors.facilitySuppliesTo &&
                              touched.facilitySuppliesTo) ||
                            (errors.facilitySuppliesFrom &&
                              touched.facilitySuppliesFrom)) && (
                            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                          )}
                        </>
                      }
                    >
                      <AdvancedInfomationFields />
                    </Tab>
                  </Tabs>
                  {error && <ErrorHandler error={error} />}
                </div>
              </Offcanvas.Body>
              <div className="d-flex sticky-bottom p-3 bg-light">
                <div className="flex-fill">
                  <Button
                    size="sm"
                    variant="outline-primary"
                    className="me-2"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
                <>
                  {step === "step-1" && (
                    <Button
                      variant="outline-dark"
                      size="sm"
                      className="px-4"
                      onClick={() => setStep("step-2")}
                    >
                      Next
                    </Button>
                  )}

                  {step === "step-2" && (
                    <>
                      <Button
                        variant="outline-dark"
                        size="sm"
                        onClick={() => setStep("step-1")}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outline-dark"
                        size="sm"
                        className="px-4 ms-2"
                        onClick={() => setStep("step-3")}
                      >
                        Next
                      </Button>
                    </>
                  )}

                  {(step === "step-3" || step === "step-4") && (
                    <>
                      <Button
                        variant="outline-dark"
                        className="float-start px-4"
                        size="sm"
                        onClick={() =>
                          setStep(step === "step-3" ? "step-2" : "step-3")
                        }
                      >
                        Previous
                      </Button>
                      <Button
                        type="submit"
                        size="sm"
                        className="ms-2"
                        disabled={!values.name || !isValid || isLoading}
                      >
                        {isLoading && (
                          <Spinner
                            className="me-2"
                            animation="border"
                            size="sm"
                            variant="light"
                          />
                        )}{" "}
                        Create Entity
                      </Button>
                    </>
                  )}
                </>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

FacilityCreate.propTypes = {
  onFacilityCreated: PropTypes.func.isRequired,
};

export default FacilityCreate;
