import React from "react";
import ActionItemLink from "./ActionItemLink";
import { useFetchQuestions } from "features/assessment";

const ActionItemLevelRedirect = (props) => {
  const { taskableType, path, facility, organizationId, show } = props || {};
  const { questionData } = useFetchQuestions({ ...props });

  const facilityData = [
    {
      id: 1,
      taskableType: "Entity",
      path,
      name: facility || "N/A",
    },
  ];

  const data = taskableType === "facility" ? facilityData : questionData;

  if (!show) return <></>;
  return <ActionItemLink data={data} organizationId={organizationId} />;
};

export default ActionItemLevelRedirect;
