import { Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import ExpandFullScreen from "components/ui/ExpandFullScreen";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { RISK_OPTION } from "features/organization/constants/statsFilterOptions";
import ReportPrincipleChartLarge from "components/common/ReportPrincipleChartLarge";
import { useFetchOrganizationProtocolPrincipleStats } from "features/organization/services";
import {
  aggregationOfPrincipleRiskTrends,
  defaultRisk,
  dynamicLabels,
} from "helper/reporting";

import "./OrganizationPrincipleReportRisk.scss";

function OrganizationPrincipleReportRisk({ protocol, filterPeriod }) {
  const riskLevels =
    protocol?.riskLevels && protocol?.riskLevels.length
      ? protocol?.riskLevels
      : defaultRisk;

  const { error, isLoading, organizationStats } =
    useFetchOrganizationProtocolPrincipleStats({
      option: RISK_OPTION,
      period: filterPeriod?.period,
      protocolId: protocol?.protocolId,
    });

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!organizationStats) return <></>;

  const allHaveZeroValues = organizationStats?.every(
    (item) => item?.observationsCount === 0
  );

  if (!organizationStats.length || allHaveZeroValues) return <></>;

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
        <div className="d-flex flex-row">
          <p className="text-center w-100 text-truncate text-capitalize mt-2">
            Distribution of Findings across Principles &{" "}
            {protocol?.questionOptions[0]?.name ?? "Risk Rating"}
          </p>
          <span>
            <ExpandFullScreen
              title={`Distribution of Findings across Principles & ${
                protocol?.questionOptions[0]?.name || "Risk Rating"
              }`}
            >
              <ReportPrincipleChartLarge
                size="square-container"
                labels={dynamicLabels(riskLevels)}
                data={aggregationOfPrincipleRiskTrends(
                  organizationStats,
                  riskLevels,
                  filterPeriod
                )}
              />
            </ExpandFullScreen>
          </span>
        </div>
        <hr />
        <ReportTrend
          size="square-container"
          labels={dynamicLabels(riskLevels)}
          data={aggregationOfPrincipleRiskTrends(
            organizationStats,
            riskLevels,
            filterPeriod
          )}
          chartHeight={350}
        />
      </Col>
    </Row>
  );
}

export default OrganizationPrincipleReportRisk;
