import React, { lazy, Suspense, useContext } from "react";

import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";

import Loader from "components/ui/Loader";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import SecondaryNavbar from "./SecondaryNavbar";

import "./Audit.scss";
import RolebaseMiddleware from "components/App/RolebaseMiddleware";

const Report = lazy(() => import("./Report"));
const AuditDetails = lazy(() => import("./AuditDetails"));
const CorrectiveActions = lazy(() => import("./CorrectiveActions"));

const Audit = () => {
  const { audit } = useContext(AuditContext);

  return (
    <>
      <Helmet>
        <title>{audit?.name || "Assessment"}</title>
      </Helmet>
      <SecondaryNavbar />
      <Routes>
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              <AuditDetails />
            </Suspense>
          }
        />
        <Route
          path="reports/"
          element={
            <Suspense fallback={<Loader />}>
              <Report />
            </Suspense>
          }
        />
        <Route
          path="corrective-actions/"
          element={
            <RolebaseMiddleware allowedRoles={["Contributor", "Member"]}>
              <Suspense fallback={<Loader />}>
                <CorrectiveActions />
              </Suspense>
            </RolebaseMiddleware>
          }
        />
      </Routes>
    </>
  );
};

export default Audit;
