import React, { useState, isValidElement, cloneElement } from "react";

import moment from "moment";
import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { isEmpty, uniqBy } from "lodash";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Dropdown, Form, Offcanvas, Spinner } from "react-bootstrap";

import SlateEditor from "components/ui/SlateEditor";
import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { useUpdateAudit } from "features/assessment/services";
import { useFetchAuditTags } from "features/account/services";
import { auditValidationSchema } from "features/assessment/schemas";

import GroupTypeahead from "../../common/GroupTypeahead";
import ProtocolTypeahead from "../../common/ProtocolTypeahead";

const AuditUpdate = ({ audit, children, onAuditUpdated }) => {
  const { isCertifier, isContributor } = useRoles();

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { error, isLoading, updateAudit } = useUpdateAudit({
    auditId: audit.auditId,
    onAuditUpdated: (data) => {
      handleClose();
      onAuditUpdated(data);
    },
  });

  const {
    error: tagError,
    isLoading: isSyncing,
    auditTags,
  } = useFetchAuditTags({
    fetchByDefault: show,
  });

  if (isCertifier || isContributor) return <></>;

  return (
    <>
      {isValidElement(children) ? (
        cloneElement(children, { onClickHandler: handleShow })
      ) : (
        <Dropdown.Item className="text-primary" onClick={handleShow}>
          <span translate="no" className="material-symbols-outlined md-18 me-2">
            edit
          </span>
          Update
        </Dropdown.Item>
      )}

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="w-fixed-640"
        placement="end"
        scroll
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Update Assessment</Offcanvas.Title>
        </Offcanvas.Header>
        <Formik
          validationSchema={auditValidationSchema}
          onSubmit={(values) => {
            updateAudit({
              name: values.name,
              description: values.description,
              labels: values?.tags?.length
                ? values?.tags.map((tag) => tag.auditLabelId)
                : [],
              auditGroupId:
                Array.isArray(values.group) && values.group.length
                  ? values.group?.[0]?.id
                  : "",
              protocolId:
                Array.isArray(values.protocol) && values.protocol.length
                  ? values.protocol?.[0]?.protocolId
                  : "",
              executiveSummary: values.executiveSummary,
              startedAt: values.startedAt,
              completedAt: values.completedAt,
              dueDate: values.dueDate
                ? moment(values.dueDate).format("YYYY-MM-DD hh:mm:ss")
                : "",
              facilityId: audit?.facilityId,
            });
          }}
          initialValues={{
            name: audit.name,
            description: audit.description,
            tags: audit.labels ?? [],
            executiveSummary: audit.executiveSummary,
            group: audit?.group ? [audit.group] : [],
            protocol: audit.protocol ? [audit.protocol] : [],
            startedAt: "2022-01-19 03:14:07",
            completedAt: "2022-01-19 03:14:07",
            dueDate: moment(audit?.dueDate).isValid()
              ? new Date(audit?.dueDate)
              : null,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Offcanvas.Body className="vh-100">
                <Form.Group controlId="name">
                  <Form.Label>
                    Assessment Name <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Assessment Name"
                    type="text"
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isValid={values.name && !errors.name}
                    isInvalid={!(values.name && !errors.name) && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && touched.name ? (
                      <small>{errors.name}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="protocol" className="mb-3">
                  <Form.Label>
                    Assessment Protocol <RequiredAsterisk />
                  </Form.Label>
                  <ProtocolTypeahead
                    isInvalid={errors.protocol && touched.protocol}
                    selected={values.protocol}
                    isValid={!isEmpty(values?.protocol) && !errors.protocol}
                    onChange={(protocol) => {
                      if (!protocol.length) setFieldTouched("protocol", true);
                      if (protocol.length) setFieldTouched("protocol", false);
                      setFieldValue("protocol", protocol);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.protocol && touched.protocol ? (
                      <small>{errors.protocol}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="tags" className="mb-3">
                  <Form.Label className="mb-1">Tags</Form.Label>
                  <Typeahead
                    id="tags-typeahead"
                    clearButton
                    placeholder="Select tags ..."
                    newSelectionPrefix="Add: "
                    isLoading={isSyncing}
                    labelKey={`name`}
                    isValid={
                      !!values.tags.length && !errors.tags && touched.tags
                    }
                    isInvalid={!(values.tags && !errors.tags) && touched.tags}
                    onChange={(tags) => {
                      setFieldValue(
                        "tags",
                        uniqBy(
                          tags.map((tag) => ({
                            ...tag,
                            name: tag?.name.toLowerCase(),
                          })),
                          "name"
                        )
                      );
                    }}
                    options={auditTags || []}
                    selected={values.tags}
                    multiple
                  />
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>Assessment Description (Optional)</Form.Label>
                  <Form.Control
                    rows={3}
                    placeholder="Enter Description"
                    as="textarea"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    isValid={values.description && !errors.description}
                    isInvalid={!!errors.description && touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="group" className="mb-3">
                  <Form.Label>
                    Group Assignment (Optional) <br />
                    <small>
                      An assessment can only be assigned to <strong>1</strong>{" "}
                      group
                    </small>
                  </Form.Label>
                  <GroupTypeahead
                    allowNew={true}
                    selected={values?.group}
                    facilityId={audit?.facilityId}
                    placeholder="Select or create a group"
                    isValid={!isEmpty(values?.group) && !errors?.group}
                    onChange={(group) => setFieldValue("group", group)}
                  />
                </Form.Group>
                <Form.Group
                  controlId="executiveSummary"
                  className="my-3 visually-hidden"
                >
                  <Form.Label>Executive Summary</Form.Label>
                  <div className="bg-light rounded border">
                    <SlateEditor
                      name="executiveSummary"
                      setFieldValue={setFieldValue}
                      oldValue={values.executiveSummary}
                      placeholder="Enter Executive Summary"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">Due Date</Form.Label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    type="date"
                    name="dueDate"
                    selected={values.dueDate}
                    popperPlacement="top-start"
                    className="audit-datepicker form-control"
                    onChange={(date) => setFieldValue("dueDate", date)}
                    onBlur={handleBlur}
                    isValid={values.dueDate && !errors.dueDate}
                    isInvalid={
                      !(values.dueDate && !errors.dueDate) && touched.dueDate
                    }
                    isClearable
                    placeholderText="Enter Due Date"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.dueDate && touched.dueDate ? (
                      <small>{errors.dueDate}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {tagError && <ErrorHandler error={tagError} />}
              </Offcanvas.Body>
              <div className="d-flex sticky-bottom p-3 bg-light">
                <div className="flex-fill">
                  <Button
                    size="sm"
                    onClick={handleClose}
                    variant="outline-primary"
                  >
                    Cancel
                  </Button>
                </div>
                <Button
                  size="sm"
                  type="submit"
                  disabled={!isValid || isLoading}
                >
                  {isLoading && (
                    <Spinner
                      size="sm"
                      variant="light"
                      className="me-2"
                      animation="border"
                    />
                  )}{" "}
                  Update Assessment
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

AuditUpdate.propTypes = {
  audit: PropTypes.object.isRequired,
  onAuditUpdated: PropTypes.func.isRequired,
};

export default AuditUpdate;
