import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import ErrorHandler from "components/ui/ErrorHandler";

import "./ZipFileDownload.scss";
import { useRoles } from "hooks";
import { useBulkDownloadFiles } from "features/files/services";

const ZipFileDownload = ({ files }) => {
  const { isCertifier } = useRoles();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { isLoading, error, bulkDownloadFiles } = useBulkDownloadFiles({
    handleClose,
  });

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        size="sm"
        title="download"
        variant="outline-primary"
        className="me-2"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          download
        </span>{" "}
        Bulk Download
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Download {files?.length} files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Due to the number of files, the download will take time</p>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" size="sm" onClick={handleClose}>
            Close
          </Button>
          <Button
            size="sm"
            variant="primary"
            disabled={isLoading}
            onClick={() => bulkDownloadFiles(files)}
          >
            {isLoading && (
              <Spinner
                size="sm"
                variant="light"
                className="me-2"
                animation="border"
              />
            )}{" "}
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ZipFileDownload.propTypes = {
  files: PropTypes.array.isRequired,
};

export default ZipFileDownload;
