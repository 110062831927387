import { OrganizationContext } from "contexts/OrganizationProvider";
import { useToast } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useCreateDataLink = ({ setLinks }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const toast = useToast();

  const createDataLink = (protocolId, assessments) => {
    setIsLoading(true);
    setError(null);
    const length = Math.ceil(assessments?.length / 50);
    const promises = [];
    for (let index = 0; index < length; index++) {
      const data = assessments.slice(index * 50, (index + 1) * 50);
      const dataLinkPromise = get(
        `/organizations/${organization?.id}/data-link`,
        {
          params: {
            protocol_id: protocolId,
            assessments: data,
          },
        }
      );

      promises.push(dataLinkPromise.promise);
      subscribedPromises.current.push(dataLinkPromise);
    }

    Promise.all(promises)
      .then((responses) => {
        setLinks((prev) => ({
          ...prev,
          dataLink: responses.map((res) => res.data),
          listResponseLink: responses.map((res) => res.listResponse),
        }));
        toast.success("Success", "Your link has been generated successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    isLoading,
    error,
    createDataLink,
  };
};

export default useCreateDataLink;
