import Loader from "components/ui/Loader";
import { sortBy } from "constants";
import DOMPurify from "dompurify";
import { useFetchFacilityAudits } from "features/assessment";
import { useExportGroupAssessments } from "features/entity/services";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Form,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";
import { useParams } from "react-router-dom";

const GroupExport = ({ group }) => {
  const { facilityId } = useParams();

  const [show, setShow] = useState(false);
  const [query, setQuery] = useState("");
  const [error, setError] = useState(null);
  const [sortState, setSortState] = useState(sortBy[0]);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setError(null);
  };
  const [groupList] = useState([group]);

  const {
    error: auditsError,
    isLoading: auditsLoading,
    audits,
  } = useFetchFacilityAudits({
    fetchByDefault: show,
    facilityId,
    group: groupList,
    sortState: {
      sortAsc: sortState?.name === "A - Z" ? true : false,
      sortColumn: "name",
    },
  });

  const {
    isLoading: exportLoading,
    error: exportError,
    exportGroupAssessments,
  } = useExportGroupAssessments({
    title: group?.title,
    handleClose,
  });

  const isLoading = auditsLoading || exportLoading;
  const auditList = audits?.filter((audit) =>
    audit?.name?.toLowerCase().includes(query.toLowerCase())
  );

  const isAudit = (data, auditId) => {
    if (!data?.length) return false;
    return data.some((audit) => audit.auditId === auditId);
  };

  useEffect(() => {
    setError(auditsError || exportError);
  }, [auditsError, exportError]);

  return (
    <>
      {!!group?.auditsCount && (
        <Button
          size="sm"
          title="export assessments"
          variant="outline-secondary"
          onClick={() => handleShow()}
          className="me-2"
          disabled={isLoading || !group?.id}
        >
          <span translate="no" className="material-symbols-outlined md-16">
            download
          </span>{" "}
        </Button>
      )}
      <Offcanvas
        className="w-fixed-640 overflow-auto"
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Formik
          enableReinitialize
          initialValues={{
            assessments: audits || [],
          }}
          onSubmit={(values) => {
            exportGroupAssessments(values.assessments);
          }}
        >
          {({ handleSubmit, values, setFieldValue, errors, touched }) => (
            <Form onSubmit={handleSubmit}>
              <Offcanvas.Header className="border-bottom" closeButton>
                <Offcanvas.Title>Export Assessments</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="vh-100">
                <>
                  <Row>
                    <Col xs={12}>
                      <Form.Group controlId="assessments" className="mb-3">
                        <Form.Label className="mb-1">
                          Search Assessments
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={query}
                          onChange={(e) => {
                            const q = e.target.value;
                            setQuery(q);
                          }}
                          placeholder="Search By Assessment"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="d-flex align-items-center">
                    <Col xs={4}>
                      <Form.Label className="mt-1 me-2 fw-semibold ">
                        Select Assessments
                      </Form.Label>
                    </Col>
                    <Col
                      xs={8}
                      className="d-flex align-items-center justify-content-end"
                    >
                      Sort By:
                      <Dropdown className="">
                        <Dropdown.Toggle
                          variant="outline-secondary"
                          id="dropdown-done"
                          className="ms-2 text-start border border-gray-900 bg-white text-secondary"
                        >
                          {sortState.name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="min-width-auto w-fixed-84 ms-2 text-center">
                          {sortBy.map((s) => (
                            <Dropdown.Item
                              key={s.index}
                              active={s.index === sortState.index}
                              onClick={() => setSortState(s)}
                              className="w-100"
                            >
                              {s.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    {auditsLoading ? (
                      <Loader />
                    ) : (
                      <Row>
                        <Col xs={12}>
                          <Form.Check
                            className="mb-2"
                            disabled={isLoading}
                            checked={
                              values.assessments.length === audits.length
                            }
                            onChange={(e) => {
                              const { checked } = e.target;
                              setFieldValue(
                                "assessments",
                                !checked ? [] : audits
                              );
                            }}
                            inline
                            label="Select All"
                            name="select-all"
                            type="checkbox"
                            id="select-all"
                          />
                        </Col>
                        {auditList?.map((audit) => {
                          const {
                            auditId,
                            name,
                            protocol: { name: protocolName },
                          } = audit;
                          const { failedFiles = [] } = error || {};
                          const failedAudits = failedFiles
                            .map((f) => f.audits)
                            .flat();
                          const isFailed = failedAudits.includes(auditId);

                          return (
                            <Col xs={12} className={"mb-2"} key={auditId}>
                              <Form.Check
                                disabled={isLoading}
                                checked={isAudit(values.assessments, auditId)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  setFieldValue(
                                    "assessments",
                                    checked
                                      ? [...values.assessments, audit]
                                      : values.assessments.filter(
                                          (audit) => audit.auditId !== auditId
                                        )
                                  );
                                }}
                                inline
                                label={
                                  <div
                                    className={isFailed ? "text-danger" : ""}
                                  >
                                    {name}
                                    <br />
                                    <small
                                      className={
                                        isFailed ? "text-danger" : "text-muted"
                                      }
                                    >
                                      {protocolName}
                                    </small>
                                  </div>
                                }
                                name="assessments"
                                type="checkbox"
                                id={auditId}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                    {errors.assessments && touched.assessments ? (
                      <small className="text-danger">
                        {errors.assessments}
                      </small>
                    ) : null}
                  </Row>
                  {error && (
                    <Alert
                      variant="warning"
                      className={`my-3 d-flex flex-row `}
                    >
                      <div className="me-3">
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18"
                        >
                          warning
                        </span>
                      </div>
                      <div>
                        <h5 className="mb-1">
                          <small>{`${error?.statusText || ""} Error`}</small>
                        </h5>
                        <p className="mb-1">
                          <small
                            className="text-break"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                error.message || error.data
                              ),
                            }}
                          />
                        </p>
                      </div>
                    </Alert>
                  )}
                </>
              </Offcanvas.Body>
              <div className="sticky-bottom mt-5 p-3 bg-light d-flex justify-content-between">
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={isLoading || !values.assessments.length}
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  <span>Export ({values.assessments.length})</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

export default GroupExport;
