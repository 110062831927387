import { useContext, useEffect, useRef, useState } from "react";

import { useRoles, useToast } from "hooks";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { put } from "utils/DeApi";
import { isArray } from "utils/ArrayUtils";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

const AuditBulkActions = ({ assessments, onBulkAction }) => {
  const { isAdmin } = useRoles();
  const toast = useToast();
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [locked, setLocked] = useState("");

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setError("");
    setShow(false);
    setLocked(false);
  };

  const selectedAssessments = isArray(assessments)
    ? assessments?.filter(({ selected }) => selected)
    : [];

  const updateAuditLocked = () => {
    setError("");
    setIsLoading(true);
    const selectedAuditIds = selectedAssessments.map(({ auditId }) => auditId);

    const auditPromise = put(`/organizations/${organization?.id}/lock-audits`, {
      lock: locked === "lock",
      audits: selectedAuditIds,
    });
    subscribedPromises.current.push(auditPromise);

    auditPromise.promise
      .then(({ data }) => {
        handleClose();
        onBulkAction({
          locked: locked === "lock",
          auditIds: selectedAuditIds || [],
        });

        toast.success("Success", "Assessments updated successfully");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  if (!isAdmin) return <></>;

  return (
    <>
      <Button
        size="sm"
        className="ms-2"
        variant="outline-primary"
        onClick={handleShow}
        disabled={!selectedAssessments?.length}
      >
        <span translate="no" className="material-symbols-outlined md-18">
          filter_none
        </span>{" "}
        Bulk Action <strong>{selectedAssessments?.length}</strong> Selected
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-bottom" closeButton>
          <Modal.Title>
            Bulk Action {selectedAssessments?.length} Selected
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>
              <strong>Manage Assessment(s) Accessibility</strong>
            </Form.Label>
            <Form.Check
              type={"radio"}
              checked={locked === "lock"}
              value={"lock"}
              disabled={isLoading}
              label={<>Lock assessments </>}
              onChange={() => setLocked("lock")}
            />
            <Form.Check
              type={"radio"}
              checked={locked === "unlock"}
              value={"unlock"}
              disabled={isLoading}
              label={<>Unlock assessments</>}
              onChange={() => setLocked("unlock")}
            />
          </Form.Group>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            className="px-3"
            variant="outline-primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            className="px-3"
            variant="primary"
            disabled={isLoading || !locked}
            onClick={updateAuditLocked}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                // variant="primary"
              />
            )}{" "}
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AuditBulkActions;
