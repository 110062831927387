import React from "react";
import PropTypes from "prop-types";
import { Alert, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const WhatsNewBody = ({ whatsNewContents = [], filtered = true }) => {
  let diplayedContents = filtered
    ? whatsNewContents.slice(0, 3)
    : whatsNewContents;

  if (!diplayedContents.length)
    return (
      <NavDropdown.Item>
        <Alert variant="primary" className="my-2">
          <p>No results found</p>
        </Alert>
      </NavDropdown.Item>
    );

  return diplayedContents.map((content, index) => (
    <div
      key={content.contentId}
      className={`${
        index < diplayedContents.length - 1 ? "border-bottom pb-2" : ""
      } my-2`}
    >
      <NavDropdown.Item
        target="_blank"
        rel="noreferrer"
        as="div"
        className="p-0 bg-transparent d-flex justify-content-between align-items-center"
      >
        <Link
          to={`/contents/${content.contentId}`}
          className="p-0 text-decoration-none"
        >
          {filtered ? (
            <small>{content.title}</small>
          ) : (
            <h3>{content.title} </h3>
          )}
        </Link>
        <span className="text-muted">
          <small>
            {new Date(content.createdAt).toLocaleString("default", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </small>
        </span>
      </NavDropdown.Item>
      {filtered ? (
        <small>
          {content?.description && content?.description?.length > 150
            ? content?.description?.substring(0, 157) + "..."
            : content?.description}
        </small>
      ) : (
        <small>{content.description}</small>
      )}
    </div>
  ));
};

WhatsNewBody.propTypes = {
  whatsNewContents: PropTypes.array,
  filtered: PropTypes.bool,
};

export default WhatsNewBody;
