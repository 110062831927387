import React, { useState } from "react";
import { Modal, Button, Alert, Table } from "react-bootstrap";
import moment from "moment";

const AudtiCreatedHistory = ({ history }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow} size="sm">
        Review
      </Button>

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Audit History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {history ? (
            <>
              {/* Audit Created Section */}

              <Table striped borderless>
                <thead>
                  <tr>
                    <th className="w-25">Field</th>
                    <th className="w-25">From</th>
                    <th className="w-25">To</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys({
                    ...history?.newProperties,
                    createdAt: history.audit.createdAt,
                    updatedAt: history.audit.updatedAt,
                  })
                    .filter((key) => {
                      return key !== "completedAt" && key !== "startedAt";
                    })
                    .map((key, index) => {
                      return (
                        <tr key={index}>
                          {/* Field name section */}
                          <td className="text-capitalize">
                            {key.replace(/([A-Z])/g, " $1").toLowerCase() ??
                              "-"}
                          </td>

                          {/* From/old field section */}
                          <td>
                            {key === "executiveSummary" ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: history.oldProperties[key],
                                }}
                              ></span>
                            ) : // locked status
                            key === "locked" ? (
                              history.oldProperties[key] === false ? (
                                <span
                                  translate="no"
                                  className="material-symbols-outlined md-16"
                                >
                                  lock_open
                                </span>
                              ) : history.oldProperties[key] ? (
                                <span
                                  translate="no"
                                  className="material-symbols-outlined md-16"
                                >
                                  lock
                                </span>
                              ) : (
                                "-"
                              )
                            ) : (
                              history.oldProperties[key] ?? "-"
                            )}
                          </td>

                          {/* To/New field section */}
                          <td>
                            {key === "executiveSummary" ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: history.newProperties[key],
                                }}
                              ></span>
                            ) : ["createdAt", "updatedAt"].includes(key) ? (
                              new Date(history.audit[key]).toLocaleString([], {
                                dateStyle: "short",
                                timeStyle: "short",
                              })
                            ) : ["dueDate"].includes(key) ? (
                              history.newProperties[key] ? (
                                new Date(
                                  history.newProperties[key]
                                ).toLocaleString([], {
                                  dateStyle: "short",
                                  timeStyle: "short",
                                })
                              ) : (
                                "-"
                              )
                            ) : key === "locked" ? (
                              history.newProperties[key] === false ? (
                                <span
                                  translate="no"
                                  className="material-symbols-outlined md-16"
                                >
                                  lock_open
                                </span>
                              ) : history.newProperties[key] ? (
                                <span
                                  translate="no"
                                  className="material-symbols-outlined md-16"
                                >
                                  lock
                                </span>
                              ) : (
                                "-"
                              )
                            ) : (
                              history.newProperties[key] ?? "-"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </>
          ) : (
            !history && (
              <Alert variant="info">There is no observation to show</Alert>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AudtiCreatedHistory;
