import React, { useRef, useState } from "react";

import { useRoles } from "hooks";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";

import { FacilityTypeahead } from "features/entity";
import {
  auditStatus,
  OrganizationAuditTableFilter,
  sortingColumns,
} from "features/assessment/constants/auditTablesFilters";

import ProtocolTypeahead from "../../common/ProtocolTypeahead";

import "./OrganizationAuditListFilters.scss";

const OrganizationAuditListFilters = ({
  filters,
  setFilters,
  tableSortState,
  sortAssessments,
}) => {
  const { updatedAt, status, dueDate } = filters;

  const { isContributor, isCertifier } = useRoles();
  const [updatedStartDate, updatedEndDate] = updatedAt;
  const [dueStartDate, dueEndDate] = dueDate;

  const [query, setQuery] = useState("");

  const sendSearchQuery = useRef(
    debounce(({ q, key }) => {
      setFilters((prev) => ({ ...prev, [key]: q || "", page: 1 }));
    }, 1000)
  );

  return (
    <Row>
      <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
        <small className="text-muted">Filter by Updated Date</small>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          selected={updatedStartDate}
          onChange={(date) =>
            setFilters((prev) => ({ ...prev, updatedAt: date, page: 1 }))
          }
          startDate={updatedStartDate}
          endDate={updatedEndDate}
          selectsRange
          placeholderText="Filter by Updated Date"
          monthsShown={2}
          className="form-control"
        >
          <Button
            size="sm"
            className="mb-1"
            onClick={() =>
              setFilters((prev) => ({ ...prev, updatedAt: [], page: 1 }))
            }
          >
            Clear
          </Button>
        </DatePicker>
      </Col>
      <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
        <small className="text-muted">Filter by Due Date</small>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          selected={dueStartDate}
          onChange={(date) =>
            setFilters((prev) => ({ ...prev, dueDate: date, page: 1 }))
          }
          startDate={dueStartDate}
          endDate={dueEndDate}
          selectsRange
          placeholderText="Filter by Due Date"
          monthsShown={2}
          className="form-control"
        >
          <Button
            size="sm"
            className="mb-1"
            onClick={() =>
              setFilters((prev) => ({ ...prev, dueDate: [], page: 1 }))
            }
          >
            Clear
          </Button>
        </DatePicker>
      </Col>
      {!isContributor && (
        <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
          <small className="text-muted">Filter By Entity</small>
          <FacilityTypeahead
            placeholder="Select an entity..."
            onChange={(selected) => {
              setFilters((prev) => ({
                ...prev,
                entity: selected?.length ? selected[0] : "",
                page: 1,
              }));
            }}
            selected={filters?.entity?.facilityId ? [filters.entity] : []}
          />
        </Col>
      )}
      {!isContributor && !isCertifier && (
        <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
          <small className="text-muted">Filter By Protocol</small>
          <ProtocolTypeahead
            placeholder="Select a protocol..."
            onChange={(selected) => {
              setFilters((prev) => ({
                ...prev,
                protocols: selected?.length ? selected[0] : "",
                page: 1,
              }));
            }}
            selected={filters?.protocols}
          />
        </Col>
      )}
      <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
        <small className="text-muted">Filter By Status</small>
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-secondary"
            id="dropdown-done"
            className="w-100 text-start border border-gray-900 bg-white text-secondary"
          >
            {status?.name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {auditStatus.map((s) => (
              <Dropdown.Item
                key={s.index}
                active={s.index === status.index}
                onClick={() =>
                  setFilters((prev) => ({ ...prev, status: s, page: 1 }))
                }
              >
                {s.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
        <small className="text-muted">Search By Title</small>
        <Form.Control
          type="text"
          value={query || ""}
          onChange={(e) => {
            const q = e.target.value;
            setQuery(q);
            sendSearchQuery.current({ q, key: "search" });
          }}
          placeholder="Search"
        />
      </Col>
      <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
        <small className="text-muted">Sort By</small>
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-secondary"
            id="dropdown-done"
            className="w-100 text-start border border-gray-900 bg-white text-secondary"
          >
            {tableSortState.sortColumn.includes("name") ? "Name" : "Updated"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sortingColumns.map((s) => (
              <Dropdown.Item
                key={s.index}
                active={s.value === tableSortState.sortColumn}
                onClick={() => sortAssessments(s.value)}
              >
                {s.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={12} sm={4} md={3} lg={2} className="mb-3">
        <small className="text-muted d-flex mb-2 w-100">
          Filter By View only
        </small>
        <Form.Switch
          checked={filters.locked}
          label="Show view only"
          onChange={() => {
            setFilters((prev) => ({
              ...prev,
              locked: !filters.locked,
              page: 1,
            }));
          }}
        />
      </Col>
      <Col
        xs={12}
        sm={isCertifier ? 12 : 4}
        md={isCertifier ? 6 : isContributor ? 9 : 3}
        lg={isCertifier ? 6 : isContributor ? 9 : 1}
        className="mb-3 d-flex flex-column"
      >
        <div className="flex-grow-1"></div>{" "}
        <div className="d-flex justify-content-end">
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2"
            onClick={() => {
              setQuery("");
              setFilters(OrganizationAuditTableFilter);
            }}
          >
            <span translate="no" className="material-symbols-outlined md-18">
              restart_alt
            </span>
            Reset
          </Button>
        </div>
      </Col>
    </Row>
  );
};

OrganizationAuditListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  tableSortState: PropTypes.object.isRequired,
  sortAssessments: PropTypes.func.isRequired,
};

export default OrganizationAuditListFilters;
