import React, { useState } from "react";

import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import ErrorHandler from "components/ui/ErrorHandler";
import { groupValidationSchema } from "features/entity/schemas";
import { useUpdateFacilityAuditGroup } from "features/entity/services";

const GroupUpdate = ({ group, onGroupUpdated }) => {
  const { isCertifier } = useRoles();
  const [show, setShow] = useState();

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setError(null);
  };

  const { error, isLoading, updateFacilityAuditGroup, setError } =
    useUpdateFacilityAuditGroup({
      groupId: group?.id,
      onGroupUpdated: (group) => {
        handleClose();
        onGroupUpdated(group);
      },
    });

  if (isCertifier) return <></>;

  return (
    <>
      <Button size="sm" onClick={handleShow} variant="outline-primary">
        <span translate="no" className="material-symbols-outlined md-16">
          edit
        </span>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Assessment Group</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={groupValidationSchema}
          onSubmit={(values) =>
            updateFacilityAuditGroup({ title: values.title })
          }
          initialValues={{
            title: group?.title || "",
          }}
        >
          {({
            handleSubmit,
            values,
            touched,
            isValid,
            errors,
            setFieldTouched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="title" className="mb-3">
                  <Form.Label className="mb-1">
                    Title <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Enter Group Title"
                    onBlur={(e) => {
                      setFieldValue("title", e.target.value);
                      setFieldTouched("title", true, false);
                    }}
                    value={values.title}
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                      setFieldTouched("title", true, false);
                    }}
                    isValid={values.title && !errors.title}
                    isInvalid={
                      !(values.title && !errors.title) && touched.title
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title && touched.title ? (
                      <small>{errors.title}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  className="px-3"
                  onClick={handleClose}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={
                    Object.keys(errors).length > 0 || isLoading || !isValid
                  }
                >
                  {isLoading && (
                    <Spinner
                      size="sm"
                      variant="light"
                      className="me-2"
                      animation="border"
                    />
                  )}{" "}
                  Update Group
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

GroupUpdate.propTypes = {
  group: PropTypes.object.isRequired,
  onGroupUpdated: PropTypes.func.isRequired,
};

export default GroupUpdate;
