import { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchActionItemSubscribers = ({ path }) => {
  const subscribedPromises = useRef([]);

  const [subscribers, setSubscribers] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchSubscribers = () => {
      setIsLoading(true);
      setError(null);

      const subscribersPromise = get(path);
      subscribersPromise.promise
        .then((response) => setSubscribers(response.data ?? []))
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(subscribersPromise);
    };

    if (path) fetchSubscribers();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [path]);

  return {
    subscribers,
    isLoading,
    error,
    setSubscribers,
  };
};

export default useFetchActionItemSubscribers;
