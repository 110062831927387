import { Fragment, useState } from "react";

import { useRoles } from "hooks";
import Slider from "react-slick";
import { dateFilter } from "constants";
import { camelCase, head } from "lodash";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Nav, Row, Tab, Tabs } from "react-bootstrap";

import Loader from "components/ui/Loader";
import { isArray } from "utils/ArrayUtils";
import { FacilityList } from "features/entity";
import { useActiveTab } from "features/entity";
import { settings } from "config/slickSliderConfigs";
import { PrinciplesSpiderWeb } from "features/assessment";
import { PrinciplesScoresGuage } from "features/assessment";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import DateFilterDropdown from "components/ui/DateFilterDropdown";
import { useShowObservationProperties } from "features/assessment";
import { useFetchProtocols } from "features/organization/services";
import NumericFieldStats from "components/common/NumericFieldStats";
import RadioFieldPiechart from "components/common/RadioFieldPiechart";
import { principleChartFilters } from "features/organization/constants";
import ProtocolFilterDropdown from "components/ui/ProtocolFilterDropdown";
import PrincipleFilterDropdown from "components/ui/PrincipleFilterDropdown";
import CheckboxFieldBarchart from "components/common/CheckboxFieldBarchart";
import {
  isCheckboxField,
  isNumericField,
  isRadioField,
} from "utils/fieldTypeUtil";
import { useFetchOrganizationAnswerTypeAndDynamicFields } from "features/organization/services";

import OrganizationStats from "./OrganizationStats";
import OrganizationReportRisk from "./OrganizationReportRisk";
import OrganizationActionItem from "./OrganizationActionItem";
import OrganizationBestPractices from "./OrganizationBestPractices";
import OrganizationReportCompliance from "./OrganizationReportCompliance";
import OrganizationReportConformity from "./OrganizationReportConformity";
import OrganizationPrincipleReportRisk from "./OrganizationPrincipleReportRisk";
import OrganizationPrincipleReportCompliance from "./OrganizationPrincipleReportCompliance";
import OrganizationPrincipleReportConformity from "./OrganizationPrincipleReportConformity";

import "./OrganizationOverview.scss";

const OrganizationOverview = () => {
  const [weightScoresKey, setWeightScoresKey] = useState("gauges-tab");
  const [protocol, setProtocol] = useState();
  const [showPrinciple, setShowPrinciple] = useState(principleChartFilters[0]);
  const [filterPeriod, setFilterPeriod] = useState(dateFilter[3]);
  const [radioFields, setRadioFields] = useState();
  const [checkboxFields, setCheckboxFields] = useState();
  const [numericFields, setNumericFields] = useState();
  const [weightedScores, setWeightedScores] = useState([]);

  const { key, setKey, setActiveTab } = useActiveTab();

  const isObservationsTrend = showPrinciple?.index === 1;
  const isObservationsByTopic = showPrinciple?.index === 2;
  const isFindingsAndBestPractice = showPrinciple?.index === 3;

  const navigate = useNavigate();
  const { isAdmin } = useRoles();

  const { isLoading: isExpanding, protocols } = useFetchProtocols({
    onSuccess: (protocols) => {
      const first = head(protocols);
      setProtocol(first || null);
      if (first) setActiveTab(first?.questionOptions);
    },
  });

  const { isRisk, isConformity, isCompliance } = useShowObservationProperties({
    protocol,
  });

  const {
    error,
    isLoading,
    answerTypeStats: bestPracFindings,
    dynamicFieldStats,
  } = useFetchOrganizationAnswerTypeAndDynamicFields({
    protocolId: protocol?.protocolId,
    isBestPracticeAndFindings: isFindingsAndBestPractice,
    onSuccess: (data = []) => {
      const dynamicFields = data;

      const fields = dynamicFields
        ?.filter(({ stats }) => stats.some(({ count }) => count > 0))
        .map((field) => ({
          ...field,
          show: true,
          name: field.fieldName,
          fieldType: field.fieldType,
          key: camelCase(field.fieldName),
        }));

      const radioCount = fields?.filter(({ fieldType }) =>
        isRadioField(fieldType)
      );

      const numericCount = fields?.filter(({ fieldType }) =>
        isNumericField(fieldType)
      );

      const checkboxCount = fields?.filter(({ fieldType }) =>
        isCheckboxField(fieldType)
      );

      const gauges = dynamicFields
        ?.filter(({ fieldType }) => isRadioField(fieldType))
        ?.filter((item) => isArray(item?.gauges))
        ?.flatMap((item) =>
          item.gauges.map((guage) => ({
            ...guage,
            questionTitle: item?.fieldName,
          }))
        );

      setWeightedScores(gauges);
      setRadioFields(radioCount);
      setNumericFields(numericCount);
      setCheckboxFields(checkboxCount);

      if (!isRisk && !isCompliance && !isConformity) {
        if (!!radioCount?.length) {
          return setKey(radioCount[0]?.key);
        }

        if (!!checkboxCount?.length) {
          return setKey(checkboxCount[0]?.key);
        }
      }
    },
  });

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12} xl={8} className="mb-3">
        <Row>
          <Col xs={12} className="mb-3">
            <Card className="px-3">
              <Row className="pt-3">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h2 className="m-0">Overview</h2>
                  {isAdmin && (
                    <div className="d-grid">
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() => navigate(`reports`)}
                        className="px-4 py-1.5 flex-grow-1 rounded-3 fs-6"
                      >
                        Reports
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18 ms-1"
                        >
                          arrow_forward
                        </span>{" "}
                      </Button>
                    </div>
                  )}
                </div>
                <Col md={12} xs={12} sm={12} lg={12}>
                  <OrganizationStats />
                </Col>
                <hr />
                <Col xs={12} sm={12} md={5} lg={5} xl={5} className="mb-3">
                  <ProtocolFilterDropdown
                    selected={protocol}
                    isLoading={isExpanding}
                    options={protocols || []}
                    handleOnClick={(p) => {
                      setProtocol(p);
                      setActiveTab(p.questionOptions);
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={5} lg={4} xl={4} className="mb-3">
                  <PrincipleFilterDropdown
                    selected={showPrinciple}
                    handleOnClick={(chart) => {
                      setShowPrinciple(chart);

                      if (isRisk) return setKey("risk-tab");
                      if (!isRisk && radioFields)
                        return setKey(radioFields[0]?.key);
                      if (!isRisk && checkboxFields)
                        return setKey(checkboxFields[0]?.key);
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} className="mb-3">
                  {isObservationsTrend && (
                    <DateFilterDropdown
                      selected={filterPeriod}
                      handleOnClick={(interval) => setFilterPeriod(interval)}
                    />
                  )}
                </Col>
              </Row>
              <Tab.Container
                id="left-tabs-example"
                className="bg-primary"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Nav variant="tabs" className="flex-row">
                  {[
                    {
                      key: "risk-tab",
                      show: isRisk && !isFindingsAndBestPractice,
                      name: protocol?.questionOptions[0]?.name || "Risk Rating",
                    },
                    {
                      key: "compliance-tab",
                      show: isCompliance && !isFindingsAndBestPractice,
                      name: protocol?.questionOptions[1]?.name || "Compliance",
                    },
                    {
                      key: "conformity-tab",
                      show: isConformity && !isFindingsAndBestPractice,
                      name: protocol?.questionOptions[2]?.name || "Conformity",
                    },
                    {
                      key: "weighted-scores-tab",
                      show:
                        !!weightedScores?.length && !isFindingsAndBestPractice,
                      name: "Weighted Scores",
                    },
                    ...(isArray(radioFields) && isObservationsTrend
                      ? radioFields
                      : []),
                    ...(isArray(checkboxFields) && isObservationsTrend
                      ? checkboxFields
                      : []),
                    ...(isArray(numericFields) && isObservationsTrend
                      ? numericFields
                      : []),
                  ].map((item) => {
                    if (!item.show) return <Fragment key={item?.key} />;
                    return (
                      <Nav.Item key={item?.key}>
                        <Nav.Link eventKey={item?.key}>{item?.name}</Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
                <Tab.Content className="pt-3">
                  {isLoading &&
                    !isRisk &&
                    !dynamicFieldStats &&
                    !isFindingsAndBestPractice && <Loader />}
                  <Tab.Pane eventKey="risk-tab">
                    {isRisk && isObservationsTrend && (
                      <OrganizationReportRisk
                        protocol={protocol}
                        isExpanding={isExpanding}
                        filterPeriod={filterPeriod}
                      />
                    )}
                    {isRisk && isObservationsByTopic && (
                      <OrganizationPrincipleReportRisk
                        protocol={protocol}
                        filterPeriod={filterPeriod}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="compliance-tab">
                    {isCompliance && isObservationsTrend && (
                      <OrganizationReportCompliance
                        protocol={protocol}
                        isExpanding={isExpanding}
                        filterPeriod={filterPeriod}
                      />
                    )}
                    {isCompliance && isObservationsByTopic && (
                      <OrganizationPrincipleReportCompliance
                        protocol={protocol}
                        filterPeriod={filterPeriod}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="conformity-tab">
                    {isConformity && isObservationsTrend && (
                      <OrganizationReportConformity
                        protocol={protocol}
                        isExpanding={isExpanding}
                        filterPeriod={filterPeriod}
                      />
                    )}
                    {isConformity && isObservationsByTopic && (
                      <OrganizationPrincipleReportConformity
                        protocol={protocol}
                        filterPeriod={filterPeriod}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="weighted-scores-tab">
                    <Tabs
                      className="mb-3"
                      onSelect={(e) => setWeightScoresKey(e)}
                    >
                      <Tab
                        eventKey={`gauges-tab`}
                        title={"Scores By Principles (%) - Gauges"}
                      >
                        {!!weightedScores?.length &&
                          !isFindingsAndBestPractice && (
                            <Tabs className="mb-3">
                              {Object.entries(
                                Object.groupBy(
                                  weightedScores || [],
                                  ({ questionTitle }) => questionTitle
                                )
                              )?.map(([key, value]) => {
                                return (
                                  <Tab
                                    key={`${key}`}
                                    eventKey={`${key}`}
                                    title={key}
                                  >
                                    {!!(
                                      value?.length &&
                                      value?.reduce(
                                        (acc, val) =>
                                          acc + Number(val?.maxScore),
                                        0
                                      )
                                    ) && (
                                      <Slider
                                        {...{
                                          ...settings,
                                          slidesToShow: 4,
                                          className: "pb-3",
                                        }}
                                      >
                                        {value?.map((data, index) => {
                                          const score =
                                            (data?.totalScore /
                                              data?.maxScore) *
                                            100;
                                          if (!isNaN(score)) {
                                            return (
                                              <PrinciplesScoresGuage
                                                title={`${data?.title}`}
                                                score={score?.toFixed(2) || 0}
                                                size="sm"
                                                key={index}
                                              />
                                            );
                                          } else {
                                            return false;
                                          }
                                        })}
                                      </Slider>
                                    )}
                                  </Tab>
                                );
                              })}
                            </Tabs>
                          )}
                      </Tab>
                      <Tab
                        eventKey={`spider-web-tab`}
                        title={"Scores By Principles - Spiderweb"}
                      >
                        <PrinciplesSpiderWeb
                          rerender={weightScoresKey}
                          overviewPage={true}
                          data={weightedScores.map((val) => ({
                            ...val,
                            flexibleQuestion: {
                              title: val?.questionTitle,
                            },
                            principle: {
                              principleId: val?.principleId,
                              name: val?.title,
                            },
                          }))}
                          principles={[
                            ...new Map(
                              (weightedScores || []).map((item) => [
                                item["title"],
                                item,
                              ])
                            ).values(),
                          ]}
                        />
                      </Tab>
                    </Tabs>
                  </Tab.Pane>
                  {[
                    ...(isArray(radioFields) ? radioFields : []),
                    ...(isArray(checkboxFields) ? checkboxFields : []),
                    ...(isArray(numericFields) ? numericFields : []),
                  ].map((field) => {
                    const { key, fieldType } = field || {};
                    if (!isObservationsTrend)
                      return <Tab.Pane key={key} eventKey={key} />;

                    return (
                      <Tab.Pane key={key} eventKey={key}>
                        {isRadioField(fieldType) && (
                          <RadioFieldPiechart data={field} />
                        )}
                        {isCheckboxField(fieldType) && (
                          <CheckboxFieldBarchart data={field} />
                        )}
                        {isNumericField(fieldType) && (
                          <NumericFieldStats data={field} />
                        )}
                      </Tab.Pane>
                    );
                  })}

                  {isFindingsAndBestPractice && (
                    <OrganizationBestPractices
                      data={bestPracFindings}
                      isLoading={isLoading}
                      protocol={protocol}
                      error={error}
                    />
                  )}
                </Tab.Content>
              </Tab.Container>
            </Card>
          </Col>
          <Col xs={12} className="mb-3">
            <FacilityList />
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={4} className="mb-3">
        <OrganizationActionItem />
      </Col>
    </>
  );
};

export default OrganizationOverview;
