import { useContext } from "react";

import { Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ReportAggregation from "components/common/ReportAggregation";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useFetchOrganizationProtocolStats } from "features/organization/services";
import { COMPLIANCE_OPTION } from "features/organization/constants/statsFilterOptions";
import {
  aggregationOfComlianceTrends,
  aggregationOfCompliance,
} from "helper/reporting";

import "./OrganizationReportCompliance.scss";

function OrganizationReportCompliance({ protocol, filterPeriod }) {
  const organization = useContext(OrganizationContext);

  const { error, isLoading, organizationStats } =
    useFetchOrganizationProtocolStats({
      option: COMPLIANCE_OPTION,
      period: filterPeriod?.period,
      protocolId: protocol?.protocolId,
    });

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!organizationStats) return <></>;
  if (!organizationStats.length) return <></>;

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={4} className="mb-4 border-end">
        <p className="text-center w-100 text-truncate text-capitalize">
          Distribution of {protocol?.questionOptions[1]?.name ?? "Compliance"}
        </p>
        <hr />
        <ReportAggregation
          size="square-container"
          labels={{
            compliant: "Yes",
            nonCompliant: "No",
          }}
          data={aggregationOfCompliance(organizationStats)}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={8} className="mb-3">
        <p className="text-center w-100 text-truncate text-capitalize">
          {protocol?.questionOptions[1]?.name ?? "Compliance"} trends across ·{" "}
          <small className="text-muted">{organization?.name}</small>
        </p>
        <hr />
        <ReportTrend
          size="square-container"
          labels={{
            compliant: "Yes",
            nonCompliant: "No",
          }}
          data={aggregationOfComlianceTrends(organizationStats, filterPeriod)}
        />
      </Col>
    </Row>
  );
}

export default OrganizationReportCompliance;
