import React, { useState } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { Form, Offcanvas, Tab, Tabs } from "react-bootstrap";
import * as yup from "yup";

import ErrorHandler from "components/ui/ErrorHandler";
import OverlayTriggerButton from "components/ui/OverlayTriggerButton";

import ActionItemUpdateFooter from "./ActionItemUpdateFooter";
import ActionItemUpdateComment from "./ActionItemUpdateComment";
import ActionItemUpdateEvidence from "./ActionItemUpdateEvidence";
import ActionItemUpdateGeneralInfo from "./ActionItemUpdateGeneralInfo";

import "./ActionItemUpdate.scss";
import { useUpdateActionItem } from "features/actionItems/services";
import { useRoles } from "hooks";
import { actionItemValidationSchema } from "features/actionItems/schemas";

const ActionItemUpdate = ({
  locked = false,
  actionItem,
  onActionItemDeleted,
  onActionItemUpdated,
  collapseView = false,
  count,
  step = 0,
}) => {
  const [show, setShow] = useState(false);
  const [actionItemComments, setActionItemComments] = useState([]);

  const { isCertifier } = useRoles();

  const { error, setError, isLoading, updateActionItem } = useUpdateActionItem({
    actionItem,
    onActionItemUpdate: (actionItem) => {
      onActionItemUpdated(actionItem);
      handleClose();
    },
  });

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setError(null);
  };

  if (isCertifier) return <span />;

  return (
    <>
      {collapseView ? (
        <>
          <span
            onClick={handleShow}
            className="fw-semibold mb-2 text-primary text-decoration-underline cursor-pointer"
          >
            Comments · {count ?? 0}
          </span>
        </>
      ) : (
        <OverlayTriggerButton
          toolTipMessage="Edit"
          className="ms-2"
          variant="outline-primary"
          onClickHandler={handleShow}
          disabled={locked}
          label={
            <span translate="no" className="material-symbols-outlined md-16">
              edit
            </span>
          }
        />
      )}
      <Offcanvas
        className="w-fixed-640 overflow-auto"
        show={show}
        placement="end"
      >
        <Offcanvas.Header
          onHide={handleClose}
          className="border-bottom"
          closeButton
        >
          <Offcanvas.Title>Action Item</Offcanvas.Title>
        </Offcanvas.Header>
        <div className="overflow-auto">
          <Formik
            validationSchema={actionItemValidationSchema.shape({
              comment: yup.string().min(2, "Comment is too Short!"),
            })}
            onSubmit={(values) => updateActionItem(values)}
            initialValues={{
              comment: "",
              updateComment: "",
              status: actionItem?.status ?? 0,
              item: actionItem.item,
              description: actionItem.description || "",
              assignedTo: actionItem.assignedTo ? actionItem.assignedTo : [],
              done: !!actionItem?.done,
              tags: actionItem?.tags ?? [],
              dueDate: actionItem.dueDate
                ? new Date(actionItem.dueDate)
                : new Date(),
              type: actionItem?.type,
              step,
              actionItem,
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Offcanvas.Body className="h-100">
                  <Tabs
                    id="action-item-update-tabs"
                    name="step"
                    activeKey={values.step}
                    onSelect={(k) => setFieldValue("step", +k)}
                    className="mb-2"
                  >
                    <Tab eventKey={0} title={"General Information"}>
                      <ActionItemUpdateGeneralInfo
                        show={show}
                        taskableId={actionItem?.taskableId}
                        taskableType={actionItem?.taskableType}
                        facilityId={actionItem?.audit?.facilityId}
                      />
                    </Tab>
                    <Tab eventKey={1} title={"Files"}>
                      <ActionItemUpdateEvidence
                        error={error}
                        actionItem={actionItem}
                        onActionItemUpdated={onActionItemUpdated}
                      />
                    </Tab>
                    <Tab eventKey={2} title={"Comments"}>
                      <ActionItemUpdateComment
                        actionItemComments={actionItemComments}
                        setActionItemComments={setActionItemComments}
                      />
                    </Tab>
                  </Tabs>
                  {error && <ErrorHandler error={error} />}
                </Offcanvas.Body>
                <ActionItemUpdateFooter
                  onActionItemDeleted={onActionItemDeleted}
                  setActionItemComments={setActionItemComments}
                  actionItemId={actionItem?.actionItemId}
                  handleClose={handleClose}
                  isLoading={isLoading}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Offcanvas>
    </>
  );
};

ActionItemUpdate.propTypes = {
  onActionItemUpdated: PropTypes.func.isRequired,
  actionItem: PropTypes.object.isRequired,
  collapseView: PropTypes.bool,
  count: PropTypes.number,
  onActionItemDeleted: PropTypes.func,
  step: PropTypes.number,
};

export default ActionItemUpdate;
