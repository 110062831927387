import { OrganizationContext } from "contexts/OrganizationProvider";
import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ActionItemLink = ({ data, organizationId }) => {
  const organization = useContext(OrganizationContext);

  const isCurrentOrganization = organization?.id === organizationId;

  return (
    <div className={`d-flex  gap-3`}>
      {data?.map(
        ({ taskableType, path, name }, ind) =>
          name &&
          name !== "N/A" && (
            <Button
              key={ind}
              disabled={!isCurrentOrganization}
              size="sm"
              variant={
                !isCurrentOrganization ? "outline-secondary" : "outline-primary"
              }
              as={Link}
              title={name}
              to={path}
              className={`mb-3 text-truncate py-2 w-fixed-250 ${
                !isCurrentOrganization ? "cursor-pointer-not-allowed" : ""
              }`}
            >
              {taskableType}: {name}
            </Button>
          )
      )}
    </div>
  );
};

ActionItemLink.propTypes = {
  data: PropTypes.array,
  organizationId: PropTypes.string,
};

export default ActionItemLink;
