import { dateFilter } from "constants";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { UserContext } from "contexts/UserProvider";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import formatDate from "../utils/formatDate";
import { get } from "utils/DeApi";
import { data } from "../constants";

export const ActionItemsContext = createContext();
export const ActionItemsDispatchContext = createContext(null);

const initialState = {
  actionItems: [],
  actionItemComment: [],
  isLoading: false,
  error: null,
  filters: {
    organization: {},
    level: {},
    type: {},
    status: {},
    filterPeriod: dateFilter[3],
    assignedBy: [],
    assignedTo: [],
    updatedDate: [],
    createdAt: [],
    dueDate: [],
    entity: "",
    audit: "",
    search: "",
    id: "",
    tag: [],
  },
  query: {
    search: "",
    assignedBy: "",
    assignedTo: "",
    entity: "",
    audit: "",
  },
  tableSortState: {
    sortColumn: "updated_at",
    sortAsc: false,
  },
  page: 1,
  perPage: 10,
  tableData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_ACTION_ITEMS":
      return {
        ...state,
        actionItems: action.payload,
      };
    case "SET_META":
      return {
        ...state,
        meta: action.payload,
      };
    case "SET_QUERY":
      return {
        ...state,
        query: { ...state.query, ...action.payload },
      };

    case "CREATE_ACTION_ITEM":
      return {
        ...state,
        actionItems: [...state.actionItems, action.payload],
      };
    case "UPDATE_ACTION_ITEM":
      return {
        ...state,
        actionItems: state.actionItems.map((actionItem) =>
          actionItem.actionItemId === action.payload.actionItemId
            ? {
                ...action.payload,
                audit: actionItem.audit,
              }
            : actionItem
        ),
      };
    case "DELETE_ACTION_ITEM":
      return {
        ...state,
        actionItems: state.actionItems.filter(
          (actionItem) =>
            actionItem.actionItemId !== action.payload.actionItemId
        ),
      };
    case "SET_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case "SET_TABLE_SORT_STATE":
      return {
        ...state,
        tableSortState: action.payload,
      };
    case "RESET_FILTERS":
      return {
        ...state,
        filters: {
          ...initialState.filters,
          assignedTo: action.payload.assignedBy,
        },
        query: { ...initialState.query },
      };
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      };
    case "SET_PER_PAGE":
      return {
        ...state,
        perPage: action.payload,
      };
    case "SET_ACTION_ITEM_COMMENT":
      return {
        ...state,
        actionItemComment: action.payload,
      };
    case "SET_TABLE_DATA":
      return {
        ...state,
        tableData: action.payload,
      };
    default:
      return state;
  }
};

export const ActionItemsContextProvider = ({ children, url }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const user = useContext(UserContext);
  const { tableSortState, filters, page, perPage } = state;
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const { createdAt, updatedDate, dueDate } = filters || {};
  const isVertical = !!process.env.REACT_APP_VERTICAL;

  const params = useCallback(() => {
    return url
      ? {
          ...(!!isVertical
            ? {
                "filter[vertical_id]": process.env.REACT_APP_VERTICAL_ID,
              }
            : {}),
          ...(filters?.organization?.id !== "all"
            ? {
                "filter[organization_id]": filters?.organization?.id,
              }
            : {}),
        }
      : {
          organizationId: organization?.id,
        };
  }, [url, isVertical, filters?.organization?.id, organization?.id]);

  const extraParams = useCallback(() => {
    const baseParams = params();
    return {
      ...baseParams,
      ...(filters?.level?.filter !== "all"
        ? {
            "filter[type]": filters?.level?.filter,
          }
        : {}),
      ...(filters?.status?.filter !== "all"
        ? { "filter[status]": filters?.status?.filter }
        : {}),
      ...(filters?.assignedBy?.length
        ? {
            "filter[assigned_by]": filters?.assignedBy
              ?.map((a) => a?.subscriberId)
              .join(","),
          }
        : {}),
      ...(filters?.assignedTo?.length
        ? {
            "filter[assigned_to]": filters?.assignedTo
              ?.map((a) => a?.subscriberId)
              .join(","),
          }
        : {}),
      ...(filters?.updatedDate?.length
        ? {
            "filter[updated_at]": formatDate(updatedDate),
          }
        : {}),
      ...(filters?.createdAt?.length
        ? {
            "filter[created_at]": formatDate(createdAt),
          }
        : {}),
      ...(filters?.dueDate?.length
        ? {
            "filter[due_date]": formatDate(dueDate),
          }
        : {}),
      ...(filters?.tag?.length
        ? {
            "filter[tags]": filters?.tag?.map((t) => t?.labelId).join(","),
          }
        : {}),
      ...(filters?.entity?.facilityId && !filters?.audit?.auditId
        ? {
            "filter[facility_id]": filters?.entity?.facilityId,
          }
        : {}),
      ...(filters?.audit?.auditId
        ? {
            "filter[audit_id]": filters?.audit?.auditId,
          }
        : {}),
      ...(filters?.search
        ? {
            "filter[search]": filters?.search,
          }
        : {}),
      ...(filters?.type?.filter !== "all"
        ? { "filter[action_item_type]": filters?.type?.filter }
        : {}),
    };
  }, [
    createdAt,
    dueDate,
    filters?.assignedBy,
    filters?.assignedTo,
    filters?.audit?.auditId,
    filters?.createdAt?.length,
    filters?.dueDate?.length,
    filters?.entity?.facilityId,
    filters?.level?.filter,
    filters?.search,
    filters?.status?.filter,
    filters?.tag,
    filters?.type?.filter,
    filters?.updatedDate?.length,
    params,
    updatedDate,
  ]);

  const fetchActionItems = useCallback(() => {
    dispatch({ type: "SET_ERROR", payload: null });
    dispatch({ type: "SET_LOADING", payload: true });
    const { sortAsc, sortColumn } = tableSortState;

    const subscribersPromise = get(
      url || `subscribers/${user?.de?.subscriberId}/action-items`,
      {
        params: {
          ...extraParams(),
          page: page ?? 1,
          perPage: perPage ?? 10,
          sort: !!sortAsc ? sortColumn : `-${sortColumn}`,
        },
      }
    );

    subscribersPromise.promise
      .then(({ data, meta }) => {
        dispatch({ type: "SET_ACTION_ITEMS", payload: data });
        dispatch({ type: "SET_META", payload: meta });
      })
      .catch((error) => {
        !error.isCanceled && dispatch({ type: "SET_ERROR", payload: error });
      })
      .finally(() => dispatch({ type: "SET_LOADING", payload: false }));

    subscribedPromises.current.push(subscribersPromise);
  }, [tableSortState, url, user?.de?.subscriberId, extraParams, page, perPage]);

  useEffect(() => {
    const updatingTableValueFromLocalStorage = () => {
      const values = JSON.parse(localStorage.getItem("tableData"));
      if (!values?.length || values?.length < data?.length) {
        dispatch({ type: "SET_TABLE_DATA", payload: data });
        localStorage.setItem("tableData", JSON.stringify(data));
      }
      if (!!values?.length) {
        const combineData = values?.map((v) => {
          const d = data.find(({ key }) => key === v.key);
          return { ...d, hidden: v.hidden };
        });
        dispatch({ type: "SET_TABLE_DATA", payload: combineData });
      }
    };
    updatingTableValueFromLocalStorage();
  }, []);

  useEffect(() => {
    fetchActionItems();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchActionItems]);

  return (
    <ActionItemsContext.Provider value={state}>
      <ActionItemsDispatchContext.Provider value={dispatch}>
        {children}
      </ActionItemsDispatchContext.Provider>
    </ActionItemsContext.Provider>
  );
};
