import React, { useCallback, useEffect, useState } from "react";

import * as yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { removeHtmlTags } from "features/actionItems/utils";
import { useCreateActionItem } from "features/actionItems/services";
import { actionItemValidationSchema } from "features/actionItems/schemas";

import {
  ActionItemAssignToField,
  ActionItemDescriptionField,
  ActionItemDueDateField,
  ActionItemEntityField,
  ActionItemTagsField,
  ActionItemTitleField,
  ActionItemTypeField,
} from "../ActionItemForm";

const ActionItemCreate = ({
  questionPrompt,
  members,
  variant,
  className,
  taskableId,
  facilityId,
  taskableType,
  onActionItemCreated,
  description = "",
  disabled,
  isDashboard = false,
  children,
}) => {
  const { isCertifier } = useRoles();
  const [show, setShow] = useState(false);
  const [node, setNode] = useState(false);

  const { error, setError, isLoading, createActionItem } = useCreateActionItem({
    onActionItemCreated: (actionItem) => {
      onActionItemCreated(actionItem);
      handleClose();
    },
    taskableType,
    taskableId,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setError(null);
  };

  const autoFocusRef = useCallback(
    (node) => node !== null && setNode(node),
    []
  );

  useEffect(() => {
    if (show && node) node.focus();
  }, [node, show]);

  if (isCertifier) return <span />;

  return (
    <>
      <Button
        size="sm"
        disabled={disabled}
        onClick={handleShow}
        variant={variant || "primary"}
        className={`float-end ${className}`}
      >
        <span translate="no" className="material-symbols-outlined md-18">
          add
        </span>{" "}
        New Action Item
      </Button>

      <Offcanvas show={show} placement="end" className="w-fixed-640">
        <Offcanvas.Header
          onHide={handleClose}
          className="border-bottom"
          closeButton
        >
          <Offcanvas.Title>
            {taskableType === "question"
              ? "New Corrective Action Item"
              : "New Action Item"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Formik
          validationSchema={actionItemValidationSchema.shape({
            entity: isDashboard
              ? yup.array().min(1).required()
              : yup.array().nullable(),
          })}
          onSubmit={(values) => {
            createActionItem(values);
          }}
          initialValues={{
            item: removeHtmlTags(questionPrompt).slice(0, 245),
            assignedTo: members || [],
            tags: [],
            dueDate: moment(new Date()).format("YYYY-MM-DD"),
            description,
            type: taskableType === "question" ? 1 : 0,
          }}
        >
          {({ handleSubmit, isValid }) => (
            <Form onSubmit={handleSubmit} className="overflow-auto">
              <Offcanvas.Body className="h-calc-120">
                <ActionItemTitleField ref={autoFocusRef} />
                <ActionItemEntityField isDashboard={isDashboard} />
                <ActionItemTypeField taskableType={taskableType} />
                {children}
                <ActionItemDescriptionField />
                <ActionItemDueDateField />
                <ActionItemAssignToField
                  show={show}
                  taskableType={taskableType}
                  taskableId={taskableId}
                  facilityId={facilityId}
                  setError={setError}
                  isDashboard={isDashboard}
                  members={members}
                />
                <ActionItemTagsField show={show} setError={setError} />

                {show && error && <ErrorHandler error={error} />}
              </Offcanvas.Body>
              <div className="d-flex justify-content-between sticky-bottom p-3 bg-light">
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={isLoading || !isValid}
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  <span>Create Action Item</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

ActionItemCreate.propTypes = {
  members: PropTypes.array,
  taskableId: PropTypes.string,
  taskableType: PropTypes.string,
  onActionItemCreated: PropTypes.func.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  isDashboard: PropTypes.bool,
  facilityId: PropTypes.string,
  dashboard: PropTypes.bool,
  children: PropTypes.node,
  variant: PropTypes.string,
  questionPrompt: PropTypes.string,
};

export default ActionItemCreate;
