import { useContext, useState } from "react";

import { Dropdown, Form, Offcanvas } from "react-bootstrap";

import { AuditContext } from "features/assessment/contexts/AuditProvider";

import AuditLock from "./AuditLock";
import AuditToggler from "./AuditToggler";
import AuditAchived from "./AuditAchived";
import AuditInActive from "./AuditInActive";
import AuditConfidential from "./AuditConfidential";

export default function AuditSettings() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const { audit, setAudit } = useContext(AuditContext);

  return (
    <>
      <Dropdown.Item onClick={handleShow}>
        <span translate="no" className="material-symbols-outlined md-18 me-2">
          more_horiz
        </span>
        Manage Assessment
      </Dropdown.Item>
      <Offcanvas
        className="w-fixed-640 overflow-auto"
        show={show}
        onHide={handleClose}
        placement="end"
        scroll
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Manage Assessment</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AuditToggler />
          <hr />
          <Form.Switch
            value={Boolean(audit?.showInapplicableSections)}
            checked={Boolean(audit?.showInapplicableSections)}
            label={<h5>View inapplicable sections</h5>}
            onChange={() =>
              setAudit((prevAudit) => ({
                ...prevAudit,
                showInapplicableSections: !prevAudit.showInapplicableSections,
              }))
            }
          />
          <p className="text-muted">
            <small>
              Admins can view all inapplicable sections in the assessment.
            </small>
          </p>
          <hr />
          <AuditInActive />
          <AuditAchived />
          <AuditConfidential />
          <AuditLock />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
