import { useContext, useState } from "react";

import { useRoles } from "hooks";
import { Form, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useUpdateAuditConfidential } from "features/assessment/services";

const AuditConfidential = () => {
  const { isAdmin } = useRoles();
  const { audit, setAudit } = useContext(AuditContext);
  const [isConfidential, setIsConfidential] = useState(audit?.confidential);
  const { error, isLoading, updateAuditConfidential } =
    useUpdateAuditConfidential({
      auditId: audit.auditId,
      onAuditUpdated: (data) => {
        setAudit((prevAudit) => ({
          ...prevAudit,
          confidential: data?.confidential,
        }));
      },
    });
  if (!isAdmin) return <></>;
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="flex-fill">
          <Form.Switch
            onChange={() => {
              setIsConfidential((prevState) => {
                updateAuditConfidential(!prevState);
                return !prevState;
              });
            }}
            type="switch"
            value={isConfidential}
            checked={isConfidential}
            disabled={isLoading}
            name="isConfidential"
            label={<h5>Confidential assessment</h5>}
          />
        </div>
        {isLoading && (
          <Spinner
            className="me-2"
            animation="border"
            size="sm"
            variant="primary"
          />
        )}
      </div>
      <p className="text-muted">
        <small>
          Activating this toggle restricts visibility of the assessment
          questions, Applicability Screening, Section is not applicable to the
          assessment, and Mark Assessment as not applicable features to only
          admins and assessment team members assigned to the questions.
        </small>
      </p>
      {error && <ErrorHandler error={error} />}
      <hr />
    </>
  );
};

export default AuditConfidential;
