import { sortBy } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchAuditFiles = ({
  auditId,
  fetchByDefault = true,
  onSuccess,
  page,
  perPage,
}) => {
  const subscribedPromises = useRef([]);

  const [files, setFiles] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState("");

  const onSuccessRef = useRef(onSuccess);

  const fetchFiles = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const filePromise = get(`audits/${auditId}/files`, {
      params: {
        sort: "updated_at",
        page: page ?? 1,
        perPage: perPage ?? 10,
      },
    });
    filePromise.promise
      .then(({ data, meta }) => {
        const sortedFiles = sortBy(data || [], "updatedAt").reverse();
        setMeta(meta);
        setFiles(sortedFiles);
        if (typeof onSuccessRef.current === "function")
          onSuccessRef.current.apply(null, [sortedFiles]);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(filePromise);
  }, [auditId, page, perPage]);

  useEffect(() => {
    if (fetchByDefault) fetchFiles();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchFiles, fetchByDefault]);

  return { error, isLoading, files, setFiles, fetchFiles, meta };
};

export default useFetchAuditFiles;
