import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { get } from "utils/DeApi";
import { sortBy } from "lodash";

const useFetchFiles = ({
  level,
  page = 1,
  perPage = 10,
  entity,
  assessment,
  fetchByDefault = true,
}) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [files, setFiles] = useState([]);
  const [error, setError] = useState();
  const [meta, setMeta] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchFiles = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const value = level?.value || "";

    const isFacility = value === "facility";
    const isQuestion = value === "question";
    const isAssessment = value === "assessment";
    const isOrganization = value === "organization";
    const filePerPage = !value ? Math.ceil(perPage / 2) : perPage;
    const mediaPerPage = !value ? perPage - filePerPage : perPage;

    const modelType = () => {
      if (isFacility) return { "filter[model_type]": "facility" };
      if (isOrganization) return { "filter[model_type]": "organization" };
      return { "filter[model_type]": "organization,facility" };
    };

    const fileOrganizationAndFacilityPromise = get(
      `organizations/${organization?.id}/media`,
      {
        params: {
          sort: "-updated_at",
          ...modelType(),
          page: page || 1,
          perPage: filePerPage || 10,
          ...(entity?.facilityId
            ? { "filter[facility_id]": entity?.facilityId }
            : {}),
        },
      }
    );

    const fileAuditPromise = get(`organizations/${organization?.id}/files`, {
      params: {
        sort: "-updated_at",
        page: page || 1,
        perPage: mediaPerPage || 10,
        ...(assessment?.auditId
          ? { "filter[audit_id]": assessment?.auditId }
          : {}),
        ...(entity?.facilityId
          ? { "filter[facility_id]": entity?.facilityId }
          : {}),
      },
    });

    Promise.all([
      fileOrganizationAndFacilityPromise.promise,
      fileAuditPromise.promise,
    ])
      .then((response) => {
        const { data: media, meta } = response[0];
        const { data: files, meta: auditMeta } = response[1];

        const filesByLevel = (media, files) => {
          if (isFacility) return [...media];
          if (isOrganization) return [...media];
          if (isAssessment || isQuestion) return [...files];
          return [...media, ...files];
        };

        const metaByLevel = (meta = {}, auditMeta = {}) => {
          if (isFacility) return meta;
          if (isOrganization) return meta;
          if (isAssessment || isQuestion) return auditMeta;
          return {
            current_page:
              meta?.current_page > auditMeta?.current_page
                ? meta?.current_page
                : auditMeta?.current_page,
            from: !value
              ? meta?.from + auditMeta?.from - 1
              : meta?.from > auditMeta?.from
              ? meta?.from
              : auditMeta?.from,
            last_page:
              meta?.last_page > auditMeta?.last_page
                ? meta?.last_page
                : auditMeta?.last_page,
            links:
              meta?.links?.length > auditMeta?.links?.length
                ? meta?.links
                : auditMeta?.links,
            path: meta?.path,
            per_page:
              meta?.per_page > auditMeta?.per_page
                ? meta?.per_page
                : auditMeta?.per_page,
            to: meta?.to + auditMeta?.to,
            total: meta?.total + auditMeta?.total,
          };
        };

        const auditFiles = files.map((f) => {
          return {
            id: f.fileId,
            fileName: f.name,
            name: f.name,
            mimeType: "",
            isFiles: true,
            modelType: f.observationId ? "question" : "assessment",
            humanReadableSize: "",
            url: f.url,
            description: f.description,
            auditId: f.auditId,
            observationId: f.observationId,
            createdAt: f.createdAt,
            updatedAt: f.updatedAt,
          };
        });

        const allFiles = sortBy(
          filesByLevel(media, auditFiles) || [],
          "updatedAt"
        ).reverse();

        setMeta(metaByLevel(meta, auditMeta));
        setFiles(allFiles);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(fileOrganizationAndFacilityPromise);
    subscribedPromises.current.push(fileAuditPromise);
  }, [
    page,
    perPage,
    level?.value,
    organization?.id,
    entity?.facilityId,
    assessment?.auditId,
  ]);

  useEffect(() => {
    if (fetchByDefault) fetchFiles();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchFiles, fetchByDefault]);

  return {
    meta,
    error,
    files,
    setFiles,
    isLoading,
    fetchFiles,
  };
};

export default useFetchFiles;
