import React from "react";
import { Button, Modal } from "react-bootstrap";

const ActionItemBulkInfoModal = ({ show, handleClose, setWarning }) => {
  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Bulk Update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The selected action items does not have same{" "}
          <strong>Assignees </strong>
          or <strong>Due Date</strong>. Please update the Assignees or Due Date.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="px-3"
          onClick={handleClose}
          variant="outline-primary"
        >
          Close
        </Button>
        <Button className="px-3" onClick={() => setWarning(false)} size="sm">
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActionItemBulkInfoModal;
