import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";

import { useFormikContext } from "formik";
import { useRoles, useToast } from "hooks";
import {
  useCreateDataLink,
  useExportData,
  useExportListResponses,
} from "services";

const DataExportFooter = ({ setLinks }) => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const {
    values: { protocolId, facilityId, assessments },
    setFieldValue,
  } = useFormikContext();

  const { isAdmin, isMember } = useRoles();

  const { exportData } = useExportData();
  const { exportListRespones } = useExportListResponses();

  const {
    isLoading: dataLinkLoading,
    error: dataLinkError,
    createDataLink,
  } = useCreateDataLink({ setLinks });

  const isDisable = () => {
    if (isLoading || dataLinkLoading) return true;
    if (isAdmin) return !protocolId || !assessments.length;
    if (isMember) return !protocolId || !assessments.length || !facilityId;
  };

  const onExportData = (protocolId, assessments) => {
    setIsLoading(true);
    setFieldValue("error", null);

    Promise.all([
      exportData(protocolId, assessments),
      exportListRespones(protocolId, assessments),
    ])
      .then((res) => {
        toast.success(
          "Success",
          "Your Files have been downloaded successfully"
        );
        res.forEach(({ csv, fileName }) => {
          if (!!csv.length) {
            let type = "data:text/csv;charset=UTF-8,%EF%BB%BF;";
            let CSVBlob = new Blob([csv], { type: type });
            saveAs(CSVBlob, fileName);
          }
        });
      })
      .catch((error) => !error.isCanceled && setFieldValue("error", error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setFieldValue("error", dataLinkError);
  }, [dataLinkError, setFieldValue]);

  return (
    <div className="sticky-bottom w-100 p-3 bg-light text-end">
      <Button
        variant="primary"
        onClick={() => onExportData(protocolId, assessments)}
        disabled={isDisable()}
      >
        {isLoading ? (
          <Spinner
            className="me-2"
            animation="border"
            size="sm"
            variant="light"
          />
        ) : (
          <span className="material-symbols-outlined md-18">download</span>
        )}{" "}
        Export To Excel (CSV)
      </Button>

      <Button
        className="ms-2"
        onClick={() => createDataLink(protocolId, assessments)}
        disabled={isDisable()}
      >
        {dataLinkLoading && (
          <Spinner
            className="me-2"
            animation="border"
            size="sm"
            variant="light"
          />
        )}{" "}
        Generate Data Link
      </Button>
    </div>
  );
};

DataExportFooter.propTypes = {
  setLinks: PropTypes.func,
};

export default DataExportFooter;
