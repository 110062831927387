import { useContext, useMemo } from "react";
import { AuditContext } from "../contexts/AuditProvider";

const useExtractMLObservationData = (observationId) => {
  const { audit } = useContext(AuditContext);

  const extractMLObservationData = useMemo(() => {
    if (observationId === "default" || !audit || !audit.protocol) return {};

    const observations = audit.questions.flatMap(
      ({ observations = [] }) => observations
    );

    const observation = observations?.find(
      (obs) => obs.observationId === observationId
    );

    if (!observation) return {};

    const allResponsesFilled = (responses) => {
      const flexibleQuestions = audit.protocol.flexibleQuestions || [];
      if (!responses || flexibleQuestions.length === 0) return false;

      if (responses.length === flexibleQuestions.length) {
        return true;
      } else {
        return false;
      }
    };

    const isFullyAnswered = allResponsesFilled(observation?.responses || []);

    const notesValue =
      observation?.responses?.find(
        (response) =>
          response.flexibleQuestion?.questionType === "Text Field" &&
          response.flexibleQuestion?.prompt
            .toLowerCase()
            .includes("provide information or observations")
      )?.notes || "";

    const conformityLevel =
      observation?.responses?.find(
        (response) =>
          response.flexibleQuestion?.prompt.toLowerCase() === "conformity level"
      )?.choice?.label || "";

    const category =
      audit.questions.find(
        (question) => question.questionId === observation?.questionId
      )?.prompt || "";

    const formattedCategory = category.replace(/<[^>]+>/g, "").toLowerCase();
    const validCategory = ["program", "training", "implementation"].find(
      (term) => formattedCategory.includes(term)
    );

    return { notesValue, conformityLevel, validCategory, isFullyAnswered };
  }, [observationId]);

  return extractMLObservationData;
};

export default useExtractMLObservationData;
