import { UserContext } from "contexts/UserProvider";
import { levels } from "features/actionItems/constants";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import { useFetchMembers } from "features/organization";
import { useRoles } from "hooks";
import MultipleTagsInput from "../MultipleTagsInput";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useFetchActionItemSubscribers } from "features/actionItems/services";

const AssignToFilter = () => {
  const filterRef = useRef(null);
  const user = useContext(UserContext);
  const {
    filters: { assignedTo },
    query: { assignedTo: query },
  } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);
  const organization = useContext(OrganizationContext);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const assignedToFromUrl = urlParams.get("assignedTo");
  const taskableType = urlParams.get("taskableType");
  const { isMember, isContributor } = useRoles();

  const path =
    isMember || isContributor
      ? ""
      : `organizations/${organization?.id}/action-item-subscribers`;

  const { subscribers: subscribersList, isLoading: subscriberLoading } =
    useFetchActionItemSubscribers({
      path,
    });

  const { members, isLoading: membersLoading } = useFetchMembers();

  const isLoading = isMember ? membersLoading : subscriberLoading;
  const subscribers = isMember ? members : subscribersList;

  const onClear = () => {
    dispatch({
      type: "SET_FILTERS",
      payload: { assignedTo: [] },
    });
    dispatch({
      type: "SET_QUERY",
      payload: { assignedTo: "" },
    });
  };

  const updateFilter = useCallback(
    (level = {}, entity = "", audit = "") => {
      dispatch({ type: "SET_FILTERS", payload: { level, entity, audit } });
    },
    [dispatch]
  );

  const prepopulatingLevel = useCallback(() => {
    if (taskableType === "organization") return updateFilter(levels[0]);
    if (taskableType === "facility") return updateFilter(levels[1]);
    if (taskableType === "question") return updateFilter(levels[2]);
    return updateFilter({});
  }, [taskableType, updateFilter]);

  const assingeesForContributors = useCallback(() => {
    dispatch({ type: "SET_FILTERS", payload: { assignedTo: [user] } });
  }, [dispatch, user]);

  useEffect(() => {
    prepopulatingLevel();
  }, [assignedToFromUrl, prepopulatingLevel, user]);

  useEffect(() => {
    if (isContributor) assingeesForContributors();
  }, [assingeesForContributors, isContributor]);

  return (
    <Col ref={filterRef} lg={2} className="mb-3 mt-2">
      <small className="text-muted">Assigned To</small>
      <Typeahead
        multiple
        className="mt-2"
        id="assigned-to"
        name="assignedTo"
        selected={assignedTo?.length ? assignedTo : []}
        isLoading={isLoading}
        labelKey={(option) => `${option.firstName} ${option.lastName}`}
        onInputChange={(val) =>
          dispatch({ type: "SET_QUERY", payload: { assignedTo: val } })
        }
        onChange={(a) => {
          dispatch({ type: "SET_FILTERS", payload: { assignedTo: a } });
          dispatch({ type: "SET_QUERY", payload: { assignedTo: "" } });
        }}
        options={subscribers}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
          return (
            <MultipleTagsInput
              {...inputProps}
              inputClassName="form-control"
              onClear={onClear}
              tags={assignedTo}
              query={query}
              filterRef={filterRef}
              disabled={isContributor}
              name="assignedTo"
            />
          );
        }}
        renderMenu={(results, { style, id }) => (
          <Menu className="typehead-menu-alignment" style={style} id={id}>
            {results.map((result, index) => (
              <MenuItem
                key={result.subscriberId}
                option={result}
                position={index}
              >
                {result.firstName
                  ? `${result.firstName} ${result.lastName}`
                  : "Load more..."}
                <br />
                <small className="text-muted">{result.email}</small>
              </MenuItem>
            ))}
          </Menu>
        )}
      />
    </Col>
  );
};

export default AssignToFilter;
