import React from "react";

import { Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import ReportPieChart from "components/common/ReportPieChart";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { aggregationOfBestPracticesAndFindings } from "helper/reporting";

import "./OrganizationBestPractices.scss";

const OrganizationBestPractices = ({ protocol, data, isLoading, error }) => {
  const graphLabels = {
    finding: "Findings",
    bestPractise: "Best Practices",
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  const allHaveZeroValues = data?.every(
    (item) => item.stats.bestPractise === 0 && item.stats.finding === 0
  );

  if (!data?.length || allHaveZeroValues) return <></>;

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={5} className="border-end">
        <p className="text-center w-100 text-truncate text-capitalize">
          Distribution of Findings and Best Practices
        </p>
        <hr />
        <ReportPieChart
          size="square-container"
          labels={graphLabels}
          data={aggregationOfBestPracticesAndFindings(data)}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={7} className="mb-2">
        <p className="text-center w-100 text-truncate text-capitalize">
          Findings and Best Practices Across Principles
          <small className="text-muted">&nbsp;&nbsp;{protocol?.name}</small>
        </p>
        <hr />
        <ReportTrend
          size="square-container"
          labels={graphLabels}
          data={aggregationOfBestPracticesAndFindings(data)}
          chartHeight={280}
        />
      </Col>
    </Row>
  );
};

export default OrganizationBestPractices;
