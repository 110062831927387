const groupAuditByProtocolId = (audits) => {
  return Array.from(
    audits.reduce((map, { protocolId, auditId, protocol }) => {
      if (!map.has(protocolId)) {
        map.set(protocolId, {
          protocolId,
          audits: [],
          name: protocol.name,
        });
      }
      map.get(protocolId).audits.push(auditId);

      return map;
    }, new Map())
  ).map(([_, value]) => value);
};

export default groupAuditByProtocolId;
