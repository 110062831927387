import React from "react";
import { Form, Pagination } from "react-bootstrap";
import { sanitizeHTML } from "utils/StringUtils";

const MetaPagination = ({ meta, setPage, perPage, setPerPage }) => {
  if (meta?.total <= 0) return <span />;

  const getPaginationItems = () => {
    if (!Array.isArray(meta?.links)) return [];

    const currentIndex = meta.links.findIndex((link) => link?.active === true);
    const totalLinks = meta.links.length;

    if (totalLinks <= 7) {
      return meta.links.filter((link) => link);
    }

    const items = [];
    const addLink = (index) => {
      if (meta.links[index]) items.push(meta.links[index]);
    };
    const addEllipsis = () => items.push({ label: "...", disabled: true });
    const addFirstPageNumber = () => items.push(meta.links[1]);
    const addLastPageNumber = () => items.push(meta.links[totalLinks - 2]);

    addLink(0);
    if (currentIndex <= 3) {
      for (let i = 1; i <= 5 && i < totalLinks - 1; i++) {
        addLink(i);
      }
      if (totalLinks > 6) addEllipsis();
      addLastPageNumber();
    } else if (currentIndex >= totalLinks - 4) {
      addFirstPageNumber();
      if (totalLinks > 6) addEllipsis();
      for (let i = totalLinks - 6; i < totalLinks - 1; i++) {
        addLink(i);
      }
    } else {
      addFirstPageNumber();
      addEllipsis();
      for (let i = currentIndex - 2; i <= currentIndex + 2; i++) {
        addLink(i);
      }
      addEllipsis();
      addLastPageNumber();
    }
    addLink(totalLinks - 1);

    return items;
  };

  const paginationItems = getPaginationItems();

  return (
    <div className="d-flex">
      <div className="flex-fill">
        <Pagination size="sm">
          {paginationItems.map((link) => (
            <Pagination.Item
              className="small"
              key={crypto.randomUUID()}
              active={link?.active}
              disabled={!link?.url}
              onClick={() => {
                if (link?.url) {
                  const searchableURL = new URL(link?.url);
                  const URLParams = new URLSearchParams(searchableURL.search);
                  setPage(URLParams.get("page"));
                }
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(link?.label),
                }}
              />
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      <small className="me-3 my-2">
        Showing<span className="mx-1">{meta?.from}</span>-
        <span className="mx-1">{meta?.to}</span>of
        <span className="ms-1">{meta?.total}</span>
      </small>

      <Form.Group className="mt-1">
        <Form.Select
          size="sm"
          onChange={(e) => {
            setPage(1);
            setPerPage(e.target.value);
          }}
          value={perPage}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={150}>150</option>
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default MetaPagination;
