import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

import { get, post } from "utils/DeApi";

const GroupTypeahead = ({
  isValid,
  selected,
  allowNew,
  onChange,
  placeholder = "Filter by group",
  facilityId,
}) => {
  const subscribedPromises = useRef([]);
  const [error, setError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [group, setGroup] = useState(Array.isArray(selected) ? selected : []);

  const createGroup = (title) => {
    setError(null);
    setIsLoading(true);
    const groupPromise = post(`/facilities/${facilityId}/audit-groups`, {
      title: title,
    });
    groupPromise.promise
      .then(({ data: group }) => {
        setGroup([group]);
        onChange([group]);
        setGroups((prevState) => [...prevState, group]);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(groupPromise);
  };

  const fetchGroups = useCallback(() => {
    setIsLoading(true);
    const groupsPromise = get(`/facilities/${facilityId}/audit-groups`, {});
    groupsPromise.promise
      .then(({ data: groups }) => setGroups(groups))
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
    subscribedPromises.current.push(groupsPromise);
  }, [facilityId]);

  useEffect(() => {
    fetchGroups();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchGroups]);

  error && console.error(error);

  return (
    <>
      <Typeahead
        clearButton
        labelKey="title"
        options={groups}
        selected={group}
        allowNew={allowNew}
        id="group-typeahead"
        isLoading={isLoading}
        placeholder={placeholder}
        isValid={Boolean(isValid)}
        newSelectionPrefix="Create: "
        onInputChange={(val) => {
          setTitleError("");
          if (val?.length > 255)
            setTitleError("Title must be less than 255 characters");
        }}
        onChange={(data) => {
          onChange(data);
          setGroup(data);
          const [{ title, customOption } = {}] = data || [];
          const isValidTitle = title?.length < 255 && customOption;
          if (isValidTitle) createGroup(data[0]?.title);
        }}
        onFocus={fetchGroups}
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((result, index) => (
              <MenuItem
                key={result.id || `new-${index}`}
                option={result}
                position={index}
              >
                {result.customOption ? (
                  <>
                    Create:{" "}
                    <span className="fw-bold">
                      {result.title.replace(/ /g, "\u00A0")}
                    </span>
                  </>
                ) : (
                  result.title.replace(/ /g, "\u00A0")
                )}
              </MenuItem>
            ))}
          </Menu>
        )}
      />
      <div className="text-danger">
        {titleError ? <small>{titleError}</small> : null}
      </div>
    </>
  );
};

GroupTypeahead.propTypes = {
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.any,
  selected: PropTypes.array,
};

export default GroupTypeahead;
