import { useState, useRef, useEffect } from "react";
import { downloadPost } from "utils/DeApi";
const useDownloadAuditRawWord = (
  auditId,
  name,
  charts,
  organization,
  showAttachments = true,
  showCitation = true,
  showExecutiveSummary = true,
  showInapplicable = true,
  showSummary = true,
  showCharts = true
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);
  const downloadAudit = () => {
    setError(null);
    setIsLoading(true);
    const fileName = `${organization} ${name} Assessment Report.docx`;
    const auditPromise = downloadPost(`audits/${auditId}/export`, {
      showAttachments: showAttachments,
      showCitation: showCitation,
      showExecutiveSummary: showExecutiveSummary,
      showInapplicable: showInapplicable,
      showSummary: showSummary,
      showCharts: showCharts,
      charts: charts?.charts?.filter((chart) => chart.image_string),
    });
    auditPromise.promise
      .then((response) => {
        setIsLoading(false);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, downloadAudit };
};
export default useDownloadAuditRawWord;
