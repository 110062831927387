import { useVisibleTags } from "features/actionItems/hooks";
import { truncate } from "lodash";
import React, { useContext, useRef, useState } from "react";
import { Badge, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./MultipleTagsInput.scss";
import { ActionItemsDispatchContext } from "features/actionItems/context";

const MultipleTagsInput = ({
  onClear,
  filterRef,
  inputClassName,
  ...inputProps
}) => {
  const [show, setShow] = useState(false);
  const dispatch = useContext(ActionItemsDispatchContext);
  const { isLoading, tags, query, disabled, name } = inputProps || {};
  const tagsRef = useRef(null);
  const { moreTags, visibleTags, clearTags } = useVisibleTags({
    tags,
    filterRef,
  });
  const isVisble = (!!tags?.length || !!query) && !isLoading && !disabled;
  const isContainerLimitedWidth = filterRef.current?.offsetWidth < 235;

  return (
    <Form.Group
      onMouseLeave={() => setShow(false)}
      className={`typeahead-input tags-input overflow-hidden ${
        !!tags.length ? "multiple-tags-input" : ""
      }`}
    >
      <div ref={tagsRef} className="d-flex">
        {Array.isArray(tags) &&
          !!visibleTags.length &&
          visibleTags?.map(({ firstName, lastName, subscriberId }) => (
            <Badge
              onMouseEnter={() => setShow(false)}
              key={subscriberId}
              className="ms-2 border assignee-tag-badge"
              title={firstName + " " + lastName}
            >
              {truncate(firstName + " " + lastName, {
                length: 15,
              })}{" "}
              {!disabled && (
                <span
                  class="material-symbols-outlined md-14 cursor-pointer"
                  onClick={() => {
                    dispatch({
                      type: "SET_FILTERS",
                      payload: {
                        [name]: tags.filter(
                          (t) => t.subscriberId !== subscriberId
                        ),
                      },
                    });
                  }}
                >
                  close
                </span>
              )}
            </Badge>
          ))}
        {!!moreTags.length && (
          <OverlayTrigger
            placement="bottom"
            show={show}
            overlay={
              <Tooltip>
                {moreTags.map(({ firstName, lastName, subscriberId }, i) => (
                  <Badge
                    key={subscriberId}
                    className="ms-2 border bg-light text-dark mb-1"
                  >
                    {firstName} {lastName} {""}
                    <span
                      class="material-symbols-outlined md-14 cursor-pointer"
                      onClick={() => {
                        dispatch({
                          type: "SET_FILTERS",
                          payload: {
                            [name]: tags.filter(
                              (t) => t.subscriberId !== subscriberId
                            ),
                          },
                        });
                      }}
                    >
                      close
                    </span>
                  </Badge>
                ))}
              </Tooltip>
            }
          >
            {moreTags.length > 0 && (
              <Badge
                className="assignee-tag-badge border ms-2"
                onMouseEnter={() => setShow(true)}
              >
                {isContainerLimitedWidth
                  ? `+${moreTags.length}`
                  : `+${moreTags.length} more`}
              </Badge>
            )}
          </OverlayTrigger>
        )}
      </div>
      <Form.Control
        {...inputProps}
        onMouseEnter={() => setShow(false)}
        className={`${!!tags?.length ? "px-0" : ""} ms-1 ${inputClassName}`}
        type="text"
        placeholder={!tags.length ? "Select an assignor..." : ""}
        value={query}
      />
      {isVisble && (
        <button
          aria-label="Clear"
          className="close btn-close rbt-close me-2"
          type="button"
          onClick={() => {
            onClear();
            clearTags();
          }}
        >
          <span className="sr-only visually-hidden">Clear</span>
        </button>
      )}
    </Form.Group>
  );
};

export default MultipleTagsInput;
