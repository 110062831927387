import * as yup from "yup";

const groupValidationSchema = yup.object().shape({
  title: yup
    .string()
    .min(2)
    .max(255, "Group Title must be at most 255 characters")
    .required("Group Title is required"),
});

export default groupValidationSchema;
