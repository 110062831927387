import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import ExpandFullScreen from "components/ui/ExpandFullScreen";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { CONFORMITY_OPTION } from "features/entity/constants/statsFilterOptions";
import { useFetchFacilityProtocolPrincipleStats } from "features/entity/services";
import ReportPrincipleChartLarge from "components/common/ReportPrincipleChartLarge";
import {
  aggregationOfPrincipleConformityTrends,
  dynamicLabels,
} from "helper/reporting";

import "./FacilityPrincipleReportConformity.scss";

function FacilityPrincipleReportConformity({
  protocol,
  facility,
  filterPeriod,
}) {
  const { error, isLoading, facilityStats } =
    useFetchFacilityProtocolPrincipleStats({
      period: filterPeriod?.period,
      option: CONFORMITY_OPTION,
      facilityId: facility?.facilityId,
      protocolId: protocol?.protocolId,
    });

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!facilityStats) return <></>;

  const allHaveZeroValues = facilityStats?.every(
    (item) => item?.observationsCount === 0
  );

  if (!facilityStats.length || allHaveZeroValues) return <Col></Col>;

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="mb-4">
        <div className="d-flex flex-row">
          <p className="text-center w-100 text-truncate text-capitalize mt-2">
            Distribution of Findings across Principles &{" "}
            {protocol?.questionOptions[2]?.name || "Conformity Level"}
          </p>
          <span>
            <ExpandFullScreen
              title={`Distribution of Findings across Principles & ${
                protocol?.questionOptions[2]?.name || "Conformity Level"
              }`}
            >
              <ReportPrincipleChartLarge
                size="square-container"
                labels={dynamicLabels(protocol?.conformity)}
                data={aggregationOfPrincipleConformityTrends(
                  facilityStats,
                  protocol?.conformity,
                  filterPeriod
                )}
              />
            </ExpandFullScreen>
          </span>
        </div>
        <hr />
        <ReportTrend
          size="square-container"
          labels={dynamicLabels(protocol?.conformity)}
          data={aggregationOfPrincipleConformityTrends(
            facilityStats,
            protocol?.conformity,
            filterPeriod
          )}
          chartHeight={400}
        />
      </Col>
    </Row>
  );
}

FacilityPrincipleReportConformity.propTypes = {
  protocol: PropTypes.object.isRequired,
  facilityId: PropTypes.string.isRequired,
  filterPeriod: PropTypes.object.isRequired,
};

export default FacilityPrincipleReportConformity;
