import { compressImage } from "utils/UploadUtils";
const uploadFile = async (files) => {
  for (let i = 0; i < files.length; i++) {
    const image = files[i];
    const isImage = image.type.startsWith("image");
    const isFormat = !["images/svg", "image/svg+xml"].includes(image.type);
    if (isImage && isFormat) {
      files[i] = await compressImage(files[i]);
    }
  }
  return [...files];
};
export { uploadFile };
