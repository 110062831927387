import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const compressImage = async (file, size = 800, quality = 1) => {
  const imageBitmap = await createImageBitmap(file);
  const { width, height } = imageBitmap || {};

  if (width < size || height < size) return file;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const targetWidth = width > height ? size : (size / height) * width;
  const targetHeight = width > height ? (size / width) * height : size;

  canvas.width = targetWidth;
  canvas.height = targetHeight;

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  ctx.drawImage(
    imageBitmap,
    0,
    0,
    width,
    height,
    0,
    0,
    targetWidth,
    targetHeight
  );

  const blob = await new Promise((resolve) =>
    canvas.toBlob(resolve, file.type, quality)
  );

  const newFile = new File([blob], file.name, {
    type: blob.type,
  });

  newFile.path = file.path;
  return newFile;
};

export const SUPPORTED_FORMATS = [
  ".avi",
  ".bmp",
  ".csv",
  ".doc",
  ".docx",
  ".fig",
  ".gif",
  ".jpeg",
  ".jpg",
  ".mov",
  ".mp3",
  ".mp4",
  ".odt",
  ".pdf",
  ".png",
  ".ppt",
  ".pptx",
  ".rtf",
  ".svg",
  ".tif",
  ".tiff",
  ".txt",
  ".vsdx",
  ".webp",
  ".xls",
  ".xlsm",
  ".xlsx",
];

export const convertToMB = (size) => {
  return Math.ceil(size / (1024 * 1024));
};

const SupportedTypesAsList = ({ formatList }) => {
  return formatList.map((format, i) => (
    <span key={i}>
      {i > 0 && ", "}
      {format}
    </span>
  ));
};

const MoreTypesToolip = ({ formatList }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <SupportedTypesAsList formatList={formatList} />
        </Tooltip>
      }
    >
      <span>
        {" and "}
        <u>more types</u>
      </span>
    </OverlayTrigger>
  );
};

export const SupportedTypesMaxSize = ({ formats, maxSize }) => {
  if (formats && maxSize) {
    return (
      <div className="d-flex flex-row mt-n2">
        <small className="text-secondary me-2">
          {"Supported formats: "}
          <SupportedTypesAsList formatList={formats.slice(0, 5)} />
          <MoreTypesToolip formatList={formats.slice(5)} />
        </small>
        <small className="text-secondary font-small ms-auto">
          {"Max size: " + maxSize + "MB"}
        </small>
      </div>
    );
  }
};

const returnType = (file = "") => {
  return file.split(".")[1];
};

export const validateImageFormat = (value) => {
  if (!value || value.length === 0) {
    return false;
  }

  const validExtensions = ["jpeg", "jpg", "png"];

  return value.every((element) => {
    const fileExtension = returnType(element?.name?.toLowerCase());
    return validExtensions.includes(fileExtension);
  });
};
