import React, { useState } from "react";

import PropTypes from "prop-types";
import { useRoles } from "hooks";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { getURLFileExtension } from "utils/StringUtils";

import "./FileDelete.scss";
import { useDeleteFile } from "features/files/services";

const FileDelete = ({ file, onFileDelete }) => {
  const { isCertifier } = useRoles();

  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setIsChecked(false);
  };

  const fileURL = file?.url || "";
  const fileEXT = getURLFileExtension(fileURL);

  const { isLoading, deleteFile, error } = useDeleteFile({
    file,
    onFileDelete: (file) => {
      handleClose();
      onFileDelete(file);
    },
  });

  const name = file?.fileName || `${file?.id}.${fileEXT}` || file?.name;

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        title="delete"
        variant="outline-danger"
        size="sm"
        className="ms-2"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          delete
        </span>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="mt-2">
          <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
            <span className="material-symbols-outlined md-24">warning</span>
          </div>
          <h2 className="text-center pt-2">Delete File</h2>
          <p className="my-3 text-center">
            <span
              className={`fiv-sqo fiv-size-sm fiv-icon-${fileEXT} me-2`}
            ></span>
            <small className="flex-fill">{name}</small>
          </p>
          <div className="mx-4 mt-2">
            <Form.Group>
              <Form.Check
                label={
                  <small>
                    By checking the box, I confirm that I want to delete this
                    File. I understand that this cannot be undone.
                  </small>
                }
                onClick={() => setIsChecked(!isChecked)}
              />
            </Form.Group>
          </div>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2 px-3"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="danger"
            className="px-3"
            onClick={deleteFile}
            disabled={isLoading || !isChecked}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

FileDelete.propTypes = {
  file: PropTypes.object.isRequired,
  onFileDelete: PropTypes.func.isRequired,
};

export default FileDelete;
