import React from "react";
import "./TypeAheadInput.scss";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const TypeAheadInput = ({ onClear, ...inputProps }) => {
  const { value, isLoading, disabled } = inputProps || {};

  return (
    <Form.Group
      className={`typeahead-input ${
        !!value ? "typeahead-active-input bg-primary bg-opacity-10" : ""
      }`}
    >
      <Form.Control {...inputProps} type="text" />
      {!!value && !isLoading && !disabled && (
        <button
          aria-label="Clear"
          className="close btn-close rbt-close me-2"
          type="button"
          onClick={() => onClear()}
        >
          <span className="sr-only visually-hidden">Clear</span>
        </button>
      )}
    </Form.Group>
  );
};

TypeAheadInput.propTypes = {
  onClear: PropTypes.func.isRequired,
  inputProps: PropTypes.object,
};

export default TypeAheadInput;
