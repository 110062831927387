import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context";
import React, { useContext, useRef } from "react";
import { Col } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useFetchMembers } from "features/organization";
import { useRoles } from "hooks";
import MultipleTagsInput from "../MultipleTagsInput";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useFetchActionItemSubscribers } from "features/actionItems/services";

const AssignByFilter = () => {
  const filterRef = useRef(null);
  const {
    filters: { assignedBy },
    query: { assignedBy: query },
  } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);
  const organization = useContext(OrganizationContext);

  const { isMember } = useRoles();

  const path = isMember
    ? ""
    : `organizations/${organization?.id}/action-item-subscribers`;

  const { subscribers: subscribersList, isLoading: subscriberLoading } =
    useFetchActionItemSubscribers({
      path,
    });

  const { members, isLoading: membersLoading } = useFetchMembers();

  const isLoading = isMember ? membersLoading : subscriberLoading;
  const subscribers = isMember ? members : subscribersList;

  const onClear = () => {
    dispatch({
      type: "SET_FILTERS",
      payload: { assignedBy: [] },
    });
    dispatch({
      type: "SET_QUERY",
      payload: { assignedBy: "" },
    });
  };

  return (
    <Col ref={filterRef} lg={2} className="mb-3 mt-2">
      <small className="text-muted">Assigned By</small>
      <Typeahead
        multiple
        className="mt-2"
        id="assigned-by"
        name="assignedBy"
        isLoading={isLoading}
        selected={assignedBy?.length ? assignedBy : []}
        labelKey={(option) => `${option.firstName} ${option.lastName}`}
        onInputChange={(val) =>
          dispatch({ type: "SET_QUERY", payload: { assignedBy: val } })
        }
        onChange={(a) => {
          dispatch({ type: "SET_FILTERS", payload: { assignedBy: a } });
          dispatch({ type: "SET_QUERY", payload: { assignedBy: "" } });
        }}
        options={subscribers}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
          return (
            <MultipleTagsInput
              {...inputProps}
              inputClassName="form-control"
              onClear={onClear}
              tags={assignedBy}
              query={query}
              filterRef={filterRef}
              name="assignedBy"
            />
          );
        }}
        renderMenu={(results, { style, id }) => {
          return (
            <Menu className="typehead-menu-alignment" style={style} id={id}>
              {results.map((result, index) => (
                <MenuItem
                  key={result.subscriberId}
                  option={result}
                  position={index}
                >
                  {result.firstName
                    ? `${result.firstName} ${result.lastName}`
                    : "Load more..."}{" "}
                  <br />
                  <small className="text-muted">{result.email}</small>
                </MenuItem>
              ))}
            </Menu>
          );
        }}
      />
    </Col>
  );
};

export default AssignByFilter;
