import { mimeTypes } from "features/files/utils/mimeTypeUtil";
import { Button, Spinner } from "react-bootstrap";

import "./PreviewRenderer.scss";

const PreviewRenderer = ({
  fileSrcURL,
  mimeType,
  fileEXT,
  downloadFile,
  isLoading,
}) => {
  if (mimeType.startsWith("image/")) {
    // Image preview
    return <img src={fileSrcURL} alt="File Preview" className="img-fluid" />;
  } else if (mimeType === mimeTypes.pdf) {
    // PDF preview
    return (
      <iframe
        src={fileSrcURL}
        title="PDF Preview"
        width={"100%"}
        height={"500em"}
      ></iframe>
    );
  } else {
    return (
      <>
        <span
          className={`pt-2 fiv-sqo fiv-size-xl fiv-icon-${fileEXT} mb-2`}
        ></span>
        <p>
          Preview is not available for this file type.
          <br />
          Please download it to view on your device.
        </p>
        <Button
          variant="primary"
          size="md"
          onClick={downloadFile}
          disabled={isLoading}
        >
          {isLoading ? <Spinner animation="border" size="sm" /> : null}
          <span className="material-symbols-outlined md-20">download</span>{" "}
          Download
        </Button>
      </>
    );
  }
};

export default PreviewRenderer;
