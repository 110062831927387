import { uniqBy } from "lodash";
import { useEffect, useState } from "react";

const useVisibleTags = ({ tags, filterRef }) => {
  const [visibleTags, setVisibleTags] = useState([]);
  const [moreTags, setMoreTags] = useState([]);

  const clearTags = () => {
    setVisibleTags([]);
    setMoreTags([]);
  };
  useEffect(() => {
    if (!tags?.length) clearTags();
  }, [tags?.length]);

  useEffect(() => {
    if (!tags?.length || !filterRef?.current) return;

    const calculateVisibleTags = () => {
      const containerWidth = filterRef.current.offsetWidth;
      const maxWidth = containerWidth * 0.75;
      let currentWidth = 0;
      const tempVisibleTags = [];
      const remainingTags = [];

      const tempDiv = document.createElement("div");
      tempDiv.style.visibility = "hidden";
      tempDiv.style.position = "absolute";
      tempDiv.className = "tag-measurement";
      document.body.appendChild(tempDiv);

      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];
        tempDiv.textContent = `${tag.firstName} ${tag.lastName}`.slice(0, 15);
        const tagWidth = tempDiv.offsetWidth + 32;

        if (currentWidth + tagWidth > maxWidth && i !== 0) {
          // Add all remaining tags to moreTags
          remainingTags.push(...tags.slice(i));
          break;
        }

        currentWidth += tagWidth;
        tempVisibleTags.push(tag);
      }

      document.body.removeChild(tempDiv);

      setVisibleTags(tempVisibleTags);
      setMoreTags(uniqBy(remainingTags, "subscriberId"));
    };

    calculateVisibleTags();
    const resizeObserver = new ResizeObserver(calculateVisibleTags);
    resizeObserver.observe(filterRef.current);

    return () => resizeObserver.disconnect();
  }, [tags, filterRef]);

  return { visibleTags, moreTags, clearTags };
};

export default useVisibleTags;
