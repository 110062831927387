import React from "react";
import { Col, Row } from "react-bootstrap";
import ActionItemBulkAssignModal from "./ActionItemBulkAssignModal";

export default function ActionItemBulkOperation({
  items,
  selectedItems,
  className = "",
  modalBtnVariant,
  onActionItemsUpdated,
  facilityId,
}) {
  return (
    <Row className={`${className} p-1`}>
      <Col>
        <div className="d-flex justify-content-center align-items-center">
          <span className="text-dark me-2">
            {selectedItems.length} selected
          </span>
          <ActionItemBulkAssignModal
            items={items}
            selectedItems={selectedItems}
            modalBtnVariant={modalBtnVariant}
            onActionItemsUpdated={onActionItemsUpdated}
            facilityId={facilityId}
            isBulkAssignDisabled={selectedItems.length < 1}
          />
        </div>
      </Col>
    </Row>
  );
}
