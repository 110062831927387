import { truncate } from "lodash";
import React, { useMemo, useState } from "react";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { removeHtmlTags } from "features/actionItems/utils";

const ActionItemCollapseDescription = ({ description = "" }) => {
  const [seeMore, setSeeMore] = useState(false);

  const cleanDescription = removeHtmlTags(description || "");

  const parseText = useMemo(() => {
    const urlRegex =
      /(\bhttps?:\/\/[^\s<]+)|(\bwww\.[^\s<]+)|(\b[a-zA-Z0-9-]+\.(?:com|org|net|edu|gov|mil|biz|info|io|co|uk|de|fr)[^\s<]*)/g;

    const truncatedText = !seeMore
      ? truncate(cleanDescription, { length: 200 })
      : cleanDescription;

    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = urlRegex.exec(truncatedText)) !== null) {
      if (match.index > lastIndex) {
        parts.push({
          type: "text",
          content: truncatedText.slice(lastIndex, match.index),
        });
      }

      parts.push({
        type: "url",
        content: match[0],
      });

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < truncatedText.length) {
      parts.push({
        type: "text",
        content: truncatedText.slice(lastIndex),
      });
    }

    return parts;
  }, [seeMore, cleanDescription]);

  return (
    !!cleanDescription?.length && (
      <Col xs={12} className="mb-3">
        <span className="fw-semibold">Description</span>
        <p className="mt-1 text-break">
          {parseText.map(({ type, content }, index) =>
            type === "url" ? (
              <a
                key={index}
                href={
                  content.startsWith("http") ? content : `https://${content}`
                }
                target="_blank"
                rel="noreferrer"
              >
                {content}
              </a>
            ) : (
              <span key={index}>{content}</span>
            )
          )}
        </p>
        {cleanDescription?.length > 200 && (
          <p
            className="text-decoration-underline cursor-pointer"
            onClick={() => setSeeMore(!seeMore)}
          >
            {!seeMore ? "See more" : "See less"}
          </p>
        )}
      </Col>
    )
  );
};

ActionItemCollapseDescription.propTypes = {
  description: PropTypes.string,
};

export default ActionItemCollapseDescription;
