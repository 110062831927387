import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Card, Button, Spinner } from "react-bootstrap";
import { useDownloadFileFromURL } from "features/entity/services";

import { useFetchFileById, useFetchMediaById } from "features/files/services";

import { getURLFileExtension } from "utils/StringUtils";
import PreviewRenderer from "./PreviewRenderer";

import "./PreviewEngine.scss";

function PreviewEngine({
  fileName,
  description,
  fileSrcURL,
  mimeType,
  fileEXT,
}) {
  const { isLoading: isDownloading, downloadFileFromURL } =
    useDownloadFileFromURL({
      fileURL: fileSrcURL,
      fileName: fileName,
    });

  const { fileId } = useParams();
  const [searchParams] = useSearchParams();
  const fileType = searchParams.get("fileType");

  const { isLoading: isExpanding, fetchMedia } = useFetchMediaById({
    mediaId: fileId,
    fetchByDefault: false,
    onSuccess: (file) => {
      const { id, name } = file;
      const fileURL = file.url || "";
      const fileEXT = getURLFileExtension(fileURL);

      downloadFileFromURL(fileURL, `${name || id}.${fileEXT}`);
    },
  });

  const { isLoading: isSynching, fetchFile } = useFetchFileById({
    fileId: fileId,
    fetchByDefault: false,
    onSuccess: (file) => {
      const { id, name } = file;
      const fileURL = file.url || "";
      const fileEXT = getURLFileExtension(fileURL);

      downloadFileFromURL(fileURL, `${name || id}.${fileEXT}`);
    },
  });

  const downloadFile = () => {
    if (fileType === "file") fetchFile();
    else fetchMedia();
  };

  const isLoading = isDownloading || isExpanding || isSynching;

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <span className="float-end">
            <Button
              variant="primary"
              size="sm"
              onClick={downloadFile}
              disabled={isLoading}
            >
              {isLoading ? <Spinner animation="border" size="sm" /> : null}
              <span className="material-symbols-outlined md-16">
                download
              </span>{" "}
              Download
            </Button>
          </span>
          <span
            className={`float-start pt-2 fiv-sqo fiv-size-md fiv-icon-${fileEXT} me-2`}
          ></span>
          <h1 className="mb-0">{fileName} preview </h1>
        </div>
      </div>
      {description ? (
        <div className="row mb-3">
          <div className="col-12">
            <p>{description}</p>
          </div>
        </div>
      ) : null}
      <Card className="border-0 px-3 w-100">
        <Card.Body>
          <div
            className="row w-100 align-items-center justify-content-center"
            style={{ minHeight: "30em" }}
          >
            <div className="col-12 text-center">
              <PreviewRenderer
                fileSrcURL={fileSrcURL}
                mimeType={mimeType}
                fileEXT={fileEXT}
                downloadFile={downloadFile}
                isLoading={isLoading}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default PreviewEngine;
