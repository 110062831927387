import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { groupAuditByProtocolId } from "../utils";
import { post } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useToast } from "hooks";

const useExportGroupAssessments = ({ title, handleClose }) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const responseHandlers = useCallback(() => {
    return {
      blob: (value) => value,
      string: (value) => new Blob([value], { type: "text/csv" }),
      data: (value) => {
        const data =
          typeof value.data === "string"
            ? value.data
            : JSON.stringify(value.data);
        return new Blob([data], { type: "text/csv" });
      },
    };
  }, []);

  const getResponseType = (value) => {
    if (value instanceof Blob) return "blob";
    if (typeof value === "string") return "string";
    if (value?.data) return "data";
    return null;
  };

  const exportGroupAssessments = useCallback(
    (audits) => {
      if (!audits?.length) return;

      setIsLoading(true);
      setError(null);
      const groupedData = groupAuditByProtocolId(audits);

      const promiseMap = [];

      for (let i = 0; i < groupedData.length; i++) {
        const { protocolId, audits } = groupedData[i];
        const exportPromise = post(
          `/organizations/${organization?.id}/stream-csv`,
          {
            protocol_id: protocolId,
            assessments: audits,
          }
        );

        promiseMap.push(exportPromise.promise);
        subscribedPromises.current.push(exportPromise);
      }

      Promise.allSettled(promiseMap)
        .then((responses) => {
          const zip = new JSZip();
          const failedFiles = [];
          const successfulFiles = [];

          responses.forEach((resp, i) => {
            const name = groupedData[i].name;
            const protocolAudits = groupedData[i].audits;

            if (resp.status !== "fulfilled" || !resp.value) {
              return failedFiles.push({
                name,
                error: resp.reason || "Failed to download",
                audits: protocolAudits,
              });
            }

            try {
              const responseType = getResponseType(resp.value);
              if (!responseType) {
                return failedFiles.push({
                  name,
                  error: "Invalid response format",
                  audits: protocolAudits,
                });
              }

              const csvData = responseHandlers()[responseType]?.(resp.value);
              if (!csvData) {
                return failedFiles.push({
                  name,
                  error: "Failed to process data",
                  audits: protocolAudits,
                });
              }

              zip.file(`${name}.csv`, csvData);
              successfulFiles.push(name);
            } catch (error) {
              failedFiles.push({
                name,
                error: error.message,
                audits: protocolAudits,
              });
            }
          });
          const failedFileNames = failedFiles
            .map(
              ({ name, error }) =>
                `<br/> - Highlighted Assessment in this ${name} are not downloaded
              ${error.data.message ? ` (${error.data?.message || ""}).` : "."}`
            )
            .join("");

          if (!successfulFiles.length)
            return setError({
              message: "No files were processed successfully.",
            });
          if (!!failedFiles.length)
            setError({
              message: `Some files failed to process: ${failedFileNames}`,
              failedFiles,
            });
          if (!failedFiles.length) handleClose();
          return zip.generateAsync({ type: "blob" });
        })
        .then((content) => {
          if (!content) return;

          const dateIndex = new Date().toLocaleString([], {
            dateStyle: "short",
            timeStyle: "short",
          });
          saveAs(content, `${title}-${dateIndex}.zip`);
          toast.success(
            "Success",
            "Your File has been downloaded successfully"
          );
        })
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));
    },
    [handleClose, organization?.id, responseHandlers, title, toast]
  );

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    exportGroupAssessments,
    isLoading,
    error,
  };
};

export default useExportGroupAssessments;
