import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Button, Alert, Navbar, Form } from "react-bootstrap";
import "./Feedback.scss";
import { post } from "utils/BeeApi";
import * as yup from "yup";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { UserContext } from "contexts/UserProvider";
import { Formik } from "formik";

const Feedback = () => {
  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  function handleCloseModal() {
    setShow(false);
    setSuccess(false);
    setError("");
  }

  function handleFeedbackSubmit(values) {
    setError(null);
    setIsLoading(true);

    let message = `${values.message} \r\n \r\n Page URL: ${window.location.href}`;
    const contactPromise = post("/forms", {
      name: user && user.firstName,
      email: user && user.email,
      subject: `Feedback - ${
        window.location.pathname.split("/")[1] || "dashboard"
      }`,
      body: message,
    });

    contactPromise.promise
      .then((data) => setSuccess(true))
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(contactPromise);
  }

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const schema = yup.object().shape({
    message: yup.string().required("Feedback message is required"),
  });

  return (
    <>
      <Navbar.Text
        className="Feedback px-2 me-1 mt-1"
        onClick={() => setShow(true)}
      >
        <span
          translate="no"
          className="material-symbols-outlined md-18 me-2 mt-0"
        >
          chat
        </span>
        Feedback
      </Navbar.Text>
      <Modal show={show} onHide={handleCloseModal} backdrop="static">
        <Formik
          validationSchema={schema}
          onSubmit={(values) => handleFeedbackSubmit(values)}
          initialValues={{
            message: "",
          }}
          validateOnMount
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Send us your feedback!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>
                    Do you have a question, comment or suggestion? Let us know.
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="message"
                    placeholder="Describe your experience here..."
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !(values.message && !errors.message) && touched.message
                    }
                    isValid={values.message && !errors.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.message && touched.message ? (
                      <small>{errors.message}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader error={isLoading} />}
                {success && (
                  <Alert variant="success" className="my-3 d-flex flex-row">
                    <div className="me-3">
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18"
                      >
                        check_circle
                      </span>
                    </div>
                    <div>
                      <h5 className="mb-1">
                        <small>Thanks</small>
                      </h5>
                      <p className="mb-1">
                        <small>
                          We appreciate that you’ve taken the time to help us
                          improve ERMAssess.
                        </small>
                      </p>
                    </div>
                  </Alert>
                )}
              </Modal.Body>
              <Modal.Footer>
                {success ? (
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                ) : (
                  <>
                    <Button variant="link" size="sm" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      type="submit"
                      // onClick={handleSubmit}
                      disabled={!isValid || isLoading}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default Feedback;
