import PropTypes from "prop-types";
import { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ActionItemUpdate from "../ActionItemUpdate";
import ActionItemDueDateTimeLine from "./ActionItemDueDateTimeLine";
import ActionItemStatus from "../../ActionItems/ActionItemStatus";

const ActionItemCard = ({
  locked = false,
  actionItems,
  setActionItems,
  ondeletedActionItem = () => {},
  to,
  taskableId,
  taskableType,
  facilityId,
  setMeta,
}) => {
  return (
    <Card>
      <Card.Body>
        {actionItems
          .filter((ele, ind) => ind < 5)
          .map((actionItem, index, arr) => {
            const { actionItemId, item, dueDate, statusLabel } = actionItem;
            return (
              <Fragment key={actionItemId}>
                <div className="d-flex flex-row">
                  <div className="flex-fill d-flex align-items-center">
                    <small className="text-muted">ID: {actionItemId}</small>
                  </div>
                  <div className="text-end">
                    <ActionItemUpdate
                      locked={locked}
                      facilityId={facilityId || ""}
                      taskableId={taskableId}
                      taskableType={taskableType}
                      actionItem={actionItem}
                      onActionItemUpdated={(actionItem) => {
                        setActionItems((prevActionItems) => {
                          return prevActionItems.map((item) =>
                            actionItem.actionItemId === item.actionItemId
                              ? actionItem
                              : item
                          );
                        });
                      }}
                      onActionItemDeleted={(deletedActionItem) => {
                        setActionItems((prevActionItems) => {
                          setMeta((prev) => ({ ...prev, total: --prev.total }));
                          const filterActionItem = prevActionItems.filter(
                            (actionItem) => {
                              return (
                                deletedActionItem?.actionItemId !==
                                actionItem?.actionItemId
                              );
                            }
                          );
                          ondeletedActionItem(taskableId);
                          return filterActionItem;
                        });
                      }}
                    />
                  </div>
                </div>
                <p className="my-3">{item}</p>
                <div className="d-flex flex-row">
                  <ActionItemStatus status={statusLabel} />
                  {statusLabel !== "Accepted" && (
                    <div className="ms-2">
                      <ActionItemDueDateTimeLine dueDate={dueDate} />
                    </div>
                  )}
                </div>
                {arr.length > ++index && <hr className="mt-3" />}
              </Fragment>
            );
          })}
        {!!actionItems.length && (
          <div className="d-grid gap-2 mt-3">
            <Button variant="light" as={Link} to={to}>
              View all{" "}
              <span
                translate="no"
                className="material-symbols-outlined md-18 ms-3"
              >
                arrow_forward
              </span>
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

ActionItemCard.propTypes = {
  actionItems: PropTypes.array.isRequired,
  setActionItems: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  taskableId: PropTypes.string,
  taskableType: PropTypes.string,
  facilityId: PropTypes.string,
  setMeta: PropTypes.func,
};

export default ActionItemCard;
