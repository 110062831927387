import { useContext, useEffect, useRef, useState } from "react";

import { useRoles } from "hooks";
import { useNavigate } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";

import { put } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { useAuditMapping } from "features/assessment/hooks";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import "./styles.scss";

const AuditInActive = () => {
  const navigate = useNavigate();
  const subscribedPromises = useRef([]);
  const { audit } = useContext(AuditContext);
  const { isAdmin, isCertifier, isContributor } = useRoles();
  const { observationsInapplicabilityMapping } = useAuditMapping();

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const inapplicableQuestions = audit?.questions
    .map(({ observations }) => {
      if (observations.length === 0) return false;
      return observations.every(({ isInapplicable }) => isInapplicable);
    })
    .filter(Boolean);

  const [toggle, setToggle] = useState(
    inapplicableQuestions.length === audit?.questions?.length
  );

  const auditInapplicable = (newToggle) => {
    setIsLoading(true);
    const observationPromises = audit?.protocol?.sections
      .filter((section) => !!section?.questions?.length)
      .map(({ sectionId }) => {
        const observationPromise = put(
          `audits/${audit?.auditId}/sections/${sectionId}/is-inapplicable`,
          {
            isInapplicable: newToggle,
          }
        );
        subscribedPromises.current.push(observationPromise);
        return observationPromise.promise;
      });
    Promise.all(observationPromises)
      .then((response) => {
        const observations = response.flatMap(({ data = [] }) => data);
        observationsInapplicabilityMapping(observations);
        navigate(`/audits/${audit?.auditId}`);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (audit.locked && !isAdmin) return <></>;
  if (isCertifier || isContributor) return <></>;
  if (audit?.confidential && !isAdmin) return <></>;
  if (!audit?.protocol?.hasQuestionInapplicability) return <></>;

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="flex-fill">
          <Form.Switch
            onChange={() => {
              setToggle((prevState) => {
                const newToggle = !prevState;
                auditInapplicable(newToggle);
                return newToggle;
              });
            }}
            type="switch"
            value={toggle}
            checked={toggle}
            disabled={isLoading || audit?.locked}
            name="isInapplicable"
            label={<h5>Assessment not applicable</h5>}
          />
        </div>
        {isLoading ? (
          <Spinner
            className="me-2"
            animation="border"
            size="sm"
            variant="primary"
          />
        ) : (
          <small>
            <strong>{inapplicableQuestions?.length}</strong> of{" "}
            <strong>{audit?.questions?.length}</strong> questions
          </small>
        )}
      </div>
      <p className="text-muted">
        <small>Admins can mark assessment as not applicable.</small>
      </p>
      {error && <ErrorHandler error={error} />}
      <hr />
    </>
  );
};

AuditInActive.propTypes = {};

export default AuditInActive;
