import { useCallback, useEffect, useRef, useState } from "react";

import { get } from "utils/DeApi";

const useFetchMLAuditSummary = ({ auditId }) => {
  const subscribedPromises = useRef([]);

  const [mlAuditSummaryData, setMLAuditSummaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchMLAuditSummaryData = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const summaryPromise = get(`ml/audits/${auditId}/summary`);

    summaryPromise.promise
      .then(({ data }) => {
        setMLAuditSummaryData(data);
        setIsLoading(false);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(summaryPromise);
  }, [auditId]);

  useEffect(() => {
    fetchMLAuditSummaryData();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchMLAuditSummaryData]);

  return {
    error,
    isLoading,
    mlAuditSummaryData,
    fetchMLAuditSummaryData,
  };
};

export default useFetchMLAuditSummary;
