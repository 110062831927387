import { useContext, useEffect, useRef, useState } from "react";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { UserContext } from "contexts/UserProvider";
import { get } from "utils/DeApi";

const useFetchSubscribers = ({ path, params }) => {
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [subscribers, setSubscribers] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchSubscribers = () => {
      setIsLoading(true);
      setError(null);

      const subscribersPromise = get(path, {
        params: {
          organizationId: organization?.id,
          ...(params ?? {}),
        },
      });
      subscribersPromise.promise
        .then((response) => setSubscribers(response.data ?? []))
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(subscribersPromise);
    };

    if (path) fetchSubscribers();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id, user, path, params]);

  return {
    subscribers,
    isLoading,
    error,
    setSubscribers,
  };
};

export default useFetchSubscribers;
