import { useRoles } from "hooks";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import AuditLabels from "features/assessment/components/ui/AuditLabels";
import AchivedBadge from "features/assessment/components/ui/AchievedBadge";
import AuditLockedBadge from "features/assessment/components/ui/AuditLockedBadge";
import AuditStatusBadge from "features/assessment/components/ui/AuditStatusBadge";
import ConfidentialBadge from "features/assessment/components/ui/ConfidentialBadge";

const OrganizationAuditListItem = ({
  error,
  isLoading,
  assessments,
  setAssessments,
}) => {
  const { isAdmin, isContributor, isCertifier } = useRoles();

  if (isLoading)
    return (
      <tr>
        <td colSpan={8}>
          <Loader />
        </td>
      </tr>
    );

  if (error)
    return (
      <tr>
        <td colSpan={8}>
          <ErrorHandler error={error} />
        </td>
      </tr>
    );

  if (!assessments) return <></>;

  if (!assessments.length)
    return (
      <tr>
        <td colSpan={8}>
          <EmptyStateHandler
            title="No assessments found"
            description="There are currently no assessments to show"
          />
        </td>
      </tr>
    );

  return assessments.map((assessment) => (
    <tr key={assessment?.auditId}>
      {isAdmin && (
        <td>
          <Form.Check
            type="Checkbox"
            id={`${assessment?.auditId}`}
            checked={!!assessment?.selected}
            onChange={(ev) => {
              setAssessments((prevState) => {
                return prevState.map((audit) => {
                  if (assessment?.auditId !== audit?.auditId) return audit;
                  return {
                    ...audit,
                    selected: !audit.selected,
                  };
                });
              });
            }}
          />
        </td>
      )}
      <td>
        <h6>
          <Link
            to={`/audits/${assessment?.auditId}${
              isContributor ? "/files" : ""
            }`}
            size="sm"
            className="text-decoration-none bold"
            variant="secondary"
          >
            {assessment?.name}
          </Link>
        </h6>
        <AuditLabels labels={assessment?.labels} />
        <AchivedBadge achieved={assessment?.achieved} />
        <ConfidentialBadge confidential={assessment?.confidential} />
        <AuditLockedBadge locked={assessment?.locked} />
        <AuditStatusBadge status={assessment?.status} />
      </td>
      <td>{assessment?.facility?.name}</td>
      <td>{assessment?.protocol?.name}</td>
      <td>
        <small>
          {new Date(assessment?.updatedAt).toLocaleString([], {
            dateStyle: "short",
            timeStyle: "short",
          })}
        </small>
      </td>
      {!isContributor && (
        <td>
          <div className="d-flex justify-content-end">
            {assessment?.protocol?.hasCorrectiveAction && !isCertifier && (
              <Button
                title="Corrective Actions"
                as={Link}
                to={`/audits/${assessment?.auditId}/corrective-actions`}
                size="sm"
                variant="outline-secondary"
                className="position-relative mx-1"
              >
                <span
                  translate="no"
                  className="material-symbols-outlined md-18"
                >
                  fact_check
                </span>
              </Button>
            )}
            <Button
              as={Link}
              size="sm"
              variant="outline-primary"
              title="Assessment Report"
              to={`/audits/${assessment?.auditId}/reports`}
              className="position-relative mx-1"
            >
              <>
                <span
                  translate="no"
                  className="material-symbols-outlined md-18"
                >
                  description
                </span>
                {!!assessment?.observationReportCommentsCount && (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {assessment?.observationReportCommentsCount}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                )}
              </>
            </Button>
          </div>
        </td>
      )}
    </tr>
  ));
};

export default OrganizationAuditListItem;
