import { UserContext } from "contexts/UserProvider";
import { ActionItemsDispatchContext } from "features/actionItems/context";
import { useRoles } from "hooks";
import React, { useContext } from "react";
import { Button } from "react-bootstrap";

const ClearFilters = () => {
  const dispatch = useContext(ActionItemsDispatchContext);
  const { isContributor } = useRoles();
  const user = useContext(UserContext);
  const assignedBy = isContributor ? [user] : [];

  const resettingFilters = () => {
    dispatch({ type: "RESET_FILTERS", payload: { assignedBy } });
  };

  return (
    <Button
      size="sm"
      variant="outline-primary"
      onClick={() => resettingFilters()}
    >
      <span translate="no" className="material-symbols-outlined md-18">
        restart_alt
      </span>{" "}
      Clear Filters
    </Button>
  );
};

export default ClearFilters;
