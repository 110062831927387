import { useToast } from "hooks";
import { useEffect, useRef, useState } from "react";
import { destroy } from "utils/DeApi";

const useDeleteFile = ({ file, onFileDelete }) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const isAssessmentOrQuestion = ["assessment", "question"].includes(
    file?.modelType
  );

  const deleteFile = () => {
    setError(null);
    setIsLoading(true);

    const URL = isAssessmentOrQuestion
      ? `files/${file?.id}`
      : `media/${file?.id}`;

    const auditFileDeletePromise = destroy(URL);

    auditFileDeletePromise.promise
      .then((resp) => {
        onFileDelete(file);
        toast.success("Success", "The file has been deleted successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditFileDeletePromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return {
    deleteFile,
    error,
    isLoading,
  };
};

export default useDeleteFile;
