import { useContext, useEffect, useRef, useState } from "react";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { post } from "utils/DeApi";

const useExportListResponses = () => {
  const organization = useContext(OrganizationContext);

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const exportListRespones = (protocolId, assessments) => {
    setError(null);
    setIsLoading(true);

    const downloadObservationPromise = post(
      `/organizations/${organization?.id}/bulk-csv`,
      {
        protocol_id: protocolId,
        assessments: assessments,
      }
    );

    const promise = downloadObservationPromise.promise
      .then((resp) => ({
        fileName: `${organization?.name} List Responses.csv`,
        csv: resp,
      }))
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(downloadObservationPromise);

    return promise;
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    exportListRespones,
  };
};

export default useExportListResponses;
