export const defaultRiskLevels = [
  {
    id: 0,
    value: "Low",
  },
  {
    id: 1,
    value: "Medium",
  },
  {
    id: 2,
    value: "High",
  },
];

export function complianceMap(item) {
  const num = parseInt(item);
  if (num === 0) return "No";
  if (num === 1) return "Yes";
  return "";
}

export function riskMap(item, riskLevels = []) {
  const num = parseInt(item);
  const _risk = Array.isArray(riskLevels)
    ? riskLevels.find(({ id }) => parseInt(id) === num)
    : undefined;

  if (_risk) return _risk?.value;
  if (num === 0) return "Low";
  if (num === 1) return "Medium";
  if (num === 2) return "High";
  if (num === 3) return "Critical";
  return "";
}

export function conformityLevelMap(item, conformity = []) {
  const num = parseInt(item);
  const _conformity = Array.isArray(conformity)
    ? conformity.find(({ id }) => parseInt(id) === num)
    : undefined;
  if (_conformity) return _conformity?.value;
  if (num === 0) return "Fully Meets";
  if (num === 1) return "Substantial Conformity";
  if (num === 2) return "Partial Conformity";
  if (num === 3) return "Does Not Meet";
  if (num === 4) return "Not Applicable";
  return "";
}
export function conformityLevelPoints(item, conformity = []) {
  const num = parseInt(item);
  const _conformity = Array.isArray(conformity)
    ? conformity.find(({ id }) => parseInt(id) === num)
    : undefined;
  if (_conformity) return _conformity?.points;
  return "";
}

export const getRiskLevel = (riskLevels) => {
  if (!Array.isArray(riskLevels)) return defaultRiskLevels;
  if (riskLevels.length === 0) return defaultRiskLevels;
  return riskLevels;
};

export const showRisk = (conformity, questionOptions) => {
  if (!Array.isArray(conformity) || !Array.isArray(questionOptions))
    return false;
  return !!questionOptions.find(({ id, value }) => parseInt(id) === 0 && value);
};

export const showCompliance = (conformity, questionOptions) => {
  if (!Array.isArray(conformity) || !Array.isArray(questionOptions))
    return false;
  return !!questionOptions.find(({ id, value }) => parseInt(id) === 1 && value);
};

export const showConformityLevel = (conformity, questionOptions) => {
  if (!Array.isArray(conformity) || !Array.isArray(questionOptions))
    return false;
  return !!questionOptions.find(({ id, value }) => parseInt(id) === 2 && value);
};

export const showConformityInapplicableReasons = (
  conformity,
  questionOptions
) => {
  if (!Array.isArray(conformity) || !Array.isArray(questionOptions))
    return false;
  return !!questionOptions.find(({ id, value }) => parseInt(id) === 2 && value);
};

export const showNotes = (conformity, questionOptions) => {
  if (!Array.isArray(conformity) || !Array.isArray(questionOptions))
    return false;
  if (questionOptions.every(({ value }) => value === false)) return false;
  return !!questionOptions.find(({ id, value }) => parseInt(id) === 3 && value);
};

export const showRecommendation = (conformity, questionOptions) => {
  if (!Array.isArray(conformity) || !Array.isArray(questionOptions))
    return false;
  if (questionOptions.every(({ value }) => value === false)) return false;
  return !!questionOptions.find(({ id, value }) => parseInt(id) === 4 && value);
};

export const showCasualFactor = (conformity, questionOptions) => {
  if (!Array.isArray(conformity) || !Array.isArray(questionOptions))
    return false;
  return !!questionOptions.find(({ id, value }) => parseInt(id) === 5 && value);
};

export const showCorrectiveAction = (conformity, questionOptions) => {
  if (!Array.isArray(conformity) && !Array.isArray(questionOptions))
    return false;
  if (questionOptions.every(({ value }) => value === false)) return false;
  return !!questionOptions.find(({ id, value }) => parseInt(id) === 6 && value);
};

export const validationMapping = [
  {
    id: 0,
    showField: showRisk,
    property: "risk",
    fieldType: "Radio Field",
    label: function (questionOptions = []) {
      return (
        questionOptions.find(({ id }) => id === this.id)?.name ?? "No Label"
      );
    },
    riskLevels: [],
    answerMapping: function (item) {
      const num = parseInt(item);
      const riskLevels = this.riskLevels ?? [];
      const _risk = Array.isArray(riskLevels)
        ? riskLevels.find(({ id }) => parseInt(id) === num)
        : undefined;
      if (_risk) return _risk?.value;
      if (num === 0) return "Low";
      if (num === 1) return "Medium";
      if (num === 2) return "High";
      return "";
    },
    isValid: function (args) {
      const risk = parseInt(args?.risk);
      return !isNaN(risk);
    },
  },
  {
    id: 1,
    showField: showCompliance,
    property: "compliance",
    fieldType: "Radio Field",
    label: function (questionOptions = []) {
      return (
        questionOptions.find(({ id }) => id === this.id)?.name ?? "No Label"
      );
    },
    answerMapping: complianceMap,
    isValid: function (args) {
      const compliance = parseInt(args?.compliance);
      return !isNaN(compliance);
    },
  },
  {
    id: 2,
    showField: showConformityLevel,
    property: "conformityLevel",
    fieldType: "Radio Field",
    conformity: [],
    answerMapping: function conformityLevelMap(item) {
      const num = parseInt(item);
      const conformity = this.conformity ?? [];
      const _conformity = Array.isArray(conformity)
        ? conformity.find(({ id }) => parseInt(id) === num)
        : undefined;
      if (_conformity) return _conformity?.value;

      if (num === 0) return "Fully Meets";
      if (num === 1) return "Substantial Conformity";
      if (num === 2) return "Partial Conformity";
      if (num === 3) return "Does Not Meet";
      if (num === 4) return "Not Applicable";
      return "";
    },
    label: function (questionOptions = []) {
      return (
        questionOptions.find(({ id }) => id === this.id)?.name ?? "No Label"
      );
    },
    isValid: function (args) {
      const conformityLevel = parseInt(args?.conformityLevel);
      if (!isNaN(conformityLevel) && conformityLevel !== 4) return true;
      if (conformityLevel === 4 && !!args?.conformityInapplicableReasons)
        return true;
      return false;
    },
  },
  {
    id: 3,
    showField: showNotes,
    property: "notes",
    fieldType: "Text Field",
    label: function (questionOptions = []) {
      return (
        questionOptions.find(({ id }) => id === this.id)?.name ?? "No Label"
      );
    },
    answerMapping: (data) => {
      return data;
    },
    isValid: function (args) {
      return !!args?.notes;
    },
  },
  {
    id: 4,
    showField: showRecommendation,
    property: "recommendation",
    fieldType: "Text Field",
    label: function (questionOptions = []) {
      return (
        questionOptions.find(({ id }) => id === this.id)?.name ?? "No Label"
      );
    },
    answerMapping: (data) => {
      return data;
    },
    isValid: function (args) {
      return true;
    },
  },
  {
    id: 5,
    showField: showCasualFactor,
    property: "causalFactors",
    fieldType: "Text Field",
    label: function (questionOptions = []) {
      return (
        questionOptions.find(({ id }) => id === this.id)?.name ?? "No Label"
      );
    },
    answerMapping: (data) => {
      return data;
    },
    isValid: function (args) {
      return true;
    },
  },
  {
    id: 6,
    showField: showCorrectiveAction,
    property: "correctiveAction",
    fieldType: "Text Field",
    label: function (questionOptions = []) {
      return (
        questionOptions.find(({ id }) => id === this.id)?.name ?? "No Label"
      );
    },
    answerMapping: (data) => {
      return data;
    },
    isValid: function (args) {
      const compliance = parseInt(args?.compliance);
      if (args?.correctiveAction === null) return true;
      if (isNaN(compliance)) return false;
      if (compliance === 1) return true;
      if (compliance === 0 && !!args?.correctiveAction) return true;
      return false;
    },
  },
  {
    id: 7,
    showField: showConformityInapplicableReasons,
    property: "conformityInapplicableReasons",
    fieldType: "Text Field",
    label: function (questionOptions = []) {
      return (
        questionOptions.find(({ id }) => id === this.id)?.name ??
        "Why is conformity not applicable?"
      );
    },
    answerMapping: (data) => {
      return data;
    },
    isValid: function (args) {
      return true;
    },
  },
  {
    id: 8,
    showField: () => {
      return true;
    },
    property: "isInapplicable",
    fieldType: "Radio Field",
    label: function (questionOptions = []) {
      return (
        questionOptions.find(({ id }) => id === this.id)?.name ??
        "Question does not apply to this audit"
      );
    },
    answerMapping: (item) => {
      const num = parseInt(item);
      if (num === 0) return "No";
      if (num === 1) return "Yes";
      if (item) return "Yes";
      return "No";
    },
    isValid: function (args) {
      return true;
    },
  },
];
