import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import Loader from "components/ui/Loader";
import { get, instance } from "utils/DeApi";
import { instance as v2Instance } from "utils/DeV2Api";
import { OrganizationOnboarding } from "features/organization";

import { UserContext } from "./UserProvider";

const OrganizationContext = createContext(undefined);
const OrganizationsContext = createContext(undefined);
const OrganizationDispatchContext = createContext(undefined);

const key = process.env.REACT_APP_LOCAL_STORAGE_KEY;

function OrganizationProvider({ children }) {
  const subscribedPromises = useRef([]);

  const user = useContext(UserContext);

  const [isLoading, setIsLoading] = useState();
  const [organization, setOrganization] = useState();
  const [organizations, setOrganizations] = useState();

  const dispatchContext = (org, orgs) => {
    let value = btoa(JSON.stringify(org));
    localStorage.setItem(key, value);
    if (
      instance &&
      instance.defaults &&
      instance.defaults.headers &&
      instance.defaults.headers
    ) {
      instance.defaults.headers["X-ORG-ID"] = `${org.id}`;
    }

    if (
      v2Instance &&
      v2Instance.defaults &&
      v2Instance.defaults.headers &&
      v2Instance.defaults.headers
    ) {
      v2Instance.defaults.headers["X-ORG-ID"] = `${org.id}`;
    }
    setOrganization(org);
    if (orgs) setOrganizations([...organizations, ...orgs]);
  };

  const fetchOrganizations = useCallback(() => {
    if (!user?.subscriberId) return false;

    setIsLoading(true);
    setOrganizations(null);

    const organizationsPromise = get("/organizations", {
      params: { verticalId: process.env.REACT_APP_VERTICAL_ID },
    });

    organizationsPromise.promise
      .then(({ data: organizations = [] }) => {
        setIsLoading(false);
        setOrganizations(
          organizations
            .map((organization) => ({
              ...organization,
              organizationId: organization?.id,
              subscriberId: user?.subscriberId,
            }))
            .sort((a, b) =>
              a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
            )
        );
      })
      .catch((error) => {
        if (!error.isCanceled) {
          console.warn(error);
          localStorage.removeItem(key);
          setOrganizations(null);
          setIsLoading(false);
          if (
            instance &&
            instance.defaults &&
            instance.defaults.headers &&
            instance.defaults.headers
          ) {
            instance.defaults.headers["X-ORG-ID"] = null;
          }

          if (
            v2Instance &&
            v2Instance.defaults &&
            v2Instance.defaults.headers &&
            v2Instance.defaults.headers
          ) {
            v2Instance.defaults.headers["X-ORG-ID"] = null;
          }
        }
      });
    subscribedPromises.current.push(organizationsPromise);
  }, [user]);

  useEffect(() => {
    fetchOrganizations();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchOrganizations]);

  useEffect(() => {
    const cachedOrg = localStorage.getItem(key)
      ? JSON.parse(atob(localStorage.getItem(key)))
      : null;
    let org = null;

    if (Array.isArray(organizations)) {
      if (cachedOrg)
        org = organizations.find(
          (item) => item.organizationId === cachedOrg.organizationId
        );
      if (!org) org = organizations[0];

      if (
        instance &&
        instance.defaults &&
        instance.defaults.headers &&
        instance.defaults.headers
      ) {
        instance.defaults.headers["X-ORG-ID"] = `${org.id}`;
      }

      if (
        v2Instance &&
        v2Instance.defaults &&
        v2Instance.defaults.headers &&
        v2Instance.defaults.headers
      ) {
        v2Instance.defaults.headers["X-ORG-ID"] = `${org.id}`;
      }

      setOrganization(org);
    }
  }, [organizations]);

  if (!user || window.location.pathname === "/logout") return children;
  if (isLoading) return <Loader />;
  if (!organization)
    return <OrganizationOnboarding onJoined={fetchOrganizations} />;

  return (
    <OrganizationsContext.Provider value={organizations}>
      <OrganizationContext.Provider value={organization}>
        <OrganizationDispatchContext.Provider value={dispatchContext}>
          {children}
        </OrganizationDispatchContext.Provider>
      </OrganizationContext.Provider>
    </OrganizationsContext.Provider>
  );
}

export {
  OrganizationContext,
  OrganizationDispatchContext,
  OrganizationProvider,
  OrganizationsContext,
};
