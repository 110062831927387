import JSZip from "jszip";
import { useRef, useState } from "react";
import { download } from "utils/DeApi";
import { getURLFileExtension } from "utils/StringUtils";
import { saveAs } from "file-saver";

const useBulkDownloadFiles = ({ handleClose }) => {
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const bulkDownloadFiles = (files) => {
    setError(null);
    setIsLoading(true);

    const promisesMap = files.map((file) => {
      const p = download(file.url);
      subscribedPromises.current.push(p);
      return p.promise;
    });

    Promise.allSettled(promisesMap)
      .then((response) => {
        const zip = new JSZip();

        response.forEach((file, index) => {
          if (file.status === "rejected") {
            return;
          }

          const { id, name = "", url } = files[index] || {};
          const fileEXT = getURLFileExtension(url || "");

          const fileName = () => {
            if (!name) return `${++index}_${id}.${fileEXT}`;

            const hasFileEXT = name.split(".").length > 1;

            if (hasFileEXT) return `${++index}_${name}`;
            return `${++index}_${name}.${fileEXT}`;
          };

          zip.file(fileName(), file?.value, { base64: true });
        });

        zip.generateAsync({ type: "blob" }).then(function (content) {
          const dateIndex = new Date().toLocaleString([], {
            dateStyle: "short",
            timeStyle: "short",
          });
          saveAs(content, `files-${dateIndex}.zip`);
          handleClose();
        });
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
  };

  return {
    bulkDownloadFiles,
    isLoading,
    error,
  };
};

export default useBulkDownloadFiles;
