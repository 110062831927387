import React, { useState } from "react";

import { useRoles } from "hooks";
import { Link, useParams } from "react-router-dom";
import { Button, Card, Col, Row, Table } from "react-bootstrap";

import { useFetchFacilityMedia } from "features/entity/services";

import FacilityFileUpload from "./FacilityFileUpload";
import FacilityFileListItem from "./FacilityFileListItem";
import FacilityZipFileDownload from "./FacilityZipFileDownload";
import MetaPagination from "components/ui/MetaPagination";

const FacilityFiles = () => {
  const { facilityId } = useParams();
  const { isCertifier } = useRoles();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { error, isLoading, files, meta, setFiles } = useFetchFacilityMedia({
    facilityId,
    fetchByDefault: true,
    page,
    perPage,
  });

  const onFileUploaded = (uploadedFiles) => {
    setFiles((prevFiles) => [...uploadedFiles, ...prevFiles]);
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
        <div className="d-flex flex-row mb-3 align-items-center">
          <div className="flex-grow-1">
            <h4 className="mb-0">Files & Photos</h4>
          </div>
          <div>
            <Button
              size="sm"
              as={Link}
              to={`/facilities/${facilityId}`}
              className="me-2"
              variant="outline-primary"
            >
              <span
                translate="no"
                className="material-symbols-outlined md-16 me-1"
              >
                arrow_back
              </span>
              Entity Overview
            </Button>
            <FacilityZipFileDownload files={files} />
            <FacilityFileUpload onFileUploaded={onFileUploaded} />
          </div>
        </div>
        <Card>
          <Card.Body className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Level</th>
                  <th>Updated</th>
                  {!isCertifier && <th>Action</th>}
                </tr>
              </thead>
              <tbody className="table-group-divider">
                <FacilityFileListItem
                  error={error}
                  files={files}
                  setFiles={setFiles}
                  isLoading={isLoading}
                />
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            <MetaPagination
              meta={meta}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

export default FacilityFiles;
