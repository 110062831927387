import { lazy, Suspense } from "react";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import LoaderWrapper from "components/ui/LoaderWrapper";
import { OrganizationRedirect } from "features/account";
import { Overview, Portfolios } from "features/organization";

import Layout from "./Layout";
import NoMatch from "../ui/NoMatch";
import PrivateRoute from "./PrivateRoute";
import GTMMiddleware from "./GTMMiddleware";
import RolebaseMiddleware from "./RolebaseMiddleware";
import FilePreviewMiddleware from "./FilePreviewMiddleware";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";

import "./App.scss";

const FilesPage = lazy(() => import("pages/FilesPage"));
const FilePreviewPage = lazy(() => import("pages/FilePreviewPage"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const EntityPage = lazy(() => import("pages/EntityPage"));
const LogoutPage = lazy(() => import("pages/LogoutPage"));
const SearchPage = lazy(() => import("pages/SearchPage"));
const ContentPage = lazy(() => import("pages/ContentPage"));
const ReportsPage = lazy(() => import("pages/ReportsPage"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));
const AssessmentPage = lazy(() => import("pages/AssessmentPage"));
const ActionItemsPage = lazy(() => import("pages/ActionItemsPage"));
const ActivityLogPage = lazy(() => import("pages/ActivityLogPage"));
const ProtocolListPage = lazy(() => import("pages/ProtocolListPage"));
const OrganizationPage = lazy(() => import("pages/OrganizationPage"));
const PrivacyPolicyPage = lazy(() => import("pages/PrivacyPolicyPage"));
const ResetPasswordPage = lazy(() => import("pages/ResetPasswordPage"));
const ForgotPasswordPage = lazy(() => import("pages/ForgotPasswordPage"));
const AssessmentListPage = lazy(() => import("pages/AssessmentListPage"));
const ProtocolBuilderPage = lazy(() => import("pages/ProtocolBuilderPage"));
const WhatsNewArticlesPage = lazy(() => import("pages/WhatsNewArticlesPage"));
const AccountManagementPage = lazy(() => import("pages/AccountManagementPage"));

const App = () => {
  const elements = createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route element={<Layout />}>
        <Route path="/" element={<GTMMiddleware />}>
          <Route path="/" element={<PrivateRoute />}>
            <Route
              path="*"
              element={
                <RolebaseMiddleware allowedRoles={[]}>
                  <Suspense fallback={<LoaderWrapper />}>
                    <OrganizationPage />
                  </Suspense>
                </RolebaseMiddleware>
              }
            >
              <Route index element={<Overview />} />
              <Route path="portfolios/:portfolioId" element={<Portfolios />} />
            </Route>
            <Route
              path="whats-new-articles"
              element={
                <Suspense fallback={<LoaderWrapper />}>
                  <WhatsNewArticlesPage />
                </Suspense>
              }
            />
            <Route
              path="facilities/:facilityId/*"
              element={
                <RolebaseMiddleware allowedRoles={[]}>
                  <Suspense fallback={<LoaderWrapper />}>
                    <EntityPage />
                  </Suspense>
                </RolebaseMiddleware>
              }
            />
            <Route
              path="reports/*"
              element={
                <Suspense fallback={<LoaderWrapper />}>
                  <ReportsPage />
                </Suspense>
              }
            />
            <Route
              path="protocols/:protocolId/*"
              element={
                <Suspense fallback={<LoaderWrapper />}>
                  <ProtocolBuilderPage />
                </Suspense>
              }
            />
            <Route
              path="protocols"
              element={
                <Suspense fallback={<LoaderWrapper />}>
                  <ProtocolListPage />
                </Suspense>
              }
            />
            <Route
              path="audits/:auditId/*"
              element={
                <Suspense fallback={<LoaderWrapper />}>
                  <AssessmentPage />
                </Suspense>
              }
            />
            <Route
              path="contents/:contentId"
              element={
                <Suspense fallback={<LoaderWrapper />}>
                  <ContentPage />
                </Suspense>
              }
            />
            <Route
              path="organizations/:organizationId"
              element={<OrganizationRedirect />}
            />
            <Route
              path="organizations/:organizationId/*"
              element={
                <RolebaseMiddleware allowedRoles={[]}>
                  <Suspense fallback={<LoaderWrapper />}>
                    <AccountManagementPage />
                  </Suspense>
                </RolebaseMiddleware>
              }
            />
            <Route
              path="files"
              element={
                <RolebaseMiddleware allowedRoles={[]}>
                  <Suspense fallback={<LoaderWrapper />}>
                    <FilesPage />
                  </Suspense>
                </RolebaseMiddleware>
              }
            />
            <Route
              path="files/:fileId"
              element={
                <FilePreviewMiddleware>
                  <Suspense fallback={<LoaderWrapper />}>
                    <FilePreviewPage />
                  </Suspense>
                </FilePreviewMiddleware>
              }
            />
            <Route
              path="activity-log"
              element={
                <RolebaseMiddleware allowedRoles={[]}>
                  <Suspense fallback={<LoaderWrapper />}>
                    <ActivityLogPage />
                  </Suspense>
                </RolebaseMiddleware>
              }
            />
            <Route
              path="action-items"
              element={
                <RolebaseMiddleware allowedRoles={["Contributor", "Member"]}>
                  <Suspense fallback={<LoaderWrapper />}>
                    <ActionItemsPage />
                  </Suspense>
                </RolebaseMiddleware>
              }
            />
            <Route
              path="audits"
              element={
                <Suspense fallback={<LoaderWrapper />}>
                  <AssessmentListPage />
                </Suspense>
              }
            />
            <Route
              path="logout"
              element={
                <Suspense fallback={<LoaderWrapper />}>
                  <LogoutPage />
                </Suspense>
              }
            />
            <Route
              path="profile/*"
              element={
                <RolebaseMiddleware allowedRoles={["Certifier", "Member"]}>
                  <Suspense fallback={<LoaderWrapper />}>
                    <ProfilePage />
                  </Suspense>
                </RolebaseMiddleware>
              }
            />
            <Route
              path="search/*"
              element={
                <Suspense fallback={<LoaderWrapper />}>
                  <SearchPage />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="login"
            element={
              <Suspense fallback={<LoaderWrapper />}>
                <LoginPage />
              </Suspense>
            }
          />
          <Route
            path="forgot-password"
            element={
              <Suspense fallback={<LoaderWrapper />}>
                <ForgotPasswordPage />
              </Suspense>
            }
          />
          <Route
            path="set-password"
            element={
              <Suspense fallback={<LoaderWrapper />}>
                <ForgotPasswordPage />
              </Suspense>
            }
          />
          <Route
            path="reset-password"
            element={
              <Suspense fallback={<LoaderWrapper />}>
                <ResetPasswordPage />
              </Suspense>
            }
          />
          <Route
            path="privacy-policy"
            element={
              <Suspense fallback={<LoaderWrapper />}>
                <PrivacyPolicyPage />
              </Suspense>
            }
          />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Route>
    </Route>
  );

  const router = createBrowserRouter(elements);

  return <RouterProvider router={router} />;
};

export default App;
