const OrganizationAuditTableFilter = {
  page: 1,
  perPage: 10,
  dueDate: [],
  updatedAt: [],
  entity: "",
  search: "",
  protocols: "",
  status: {
    index: 1,
    name: "All",
    value: "",
  },
  locked: false,
};

const FacilityAuditTableFilter = {
  page: 1,
  group: [],
  query: "",
  perPage: 10,
  protocol: [],
  endDueDate: null,
  startDueDate: null,
  endCreateDate: null,
  startCreateDate: null,
  sortState: {
    sortAsc: false,
    updatedAt: true,
    sortColumn: "updated_at",
  },
};

const auditStatus = [
  {
    index: 1,
    name: "All",
    value: "",
  },
  {
    index: 3,
    name: "In Progress",
    value: "0",
  },
  {
    index: 4,
    name: "Done",
    value: "1",
  },
];
const sortingColumns = [
  {
    index: 0,
    name: "Name",
    value: "name",
  },
  {
    index: 1,
    name: "Updated",
    value: "updated_at",
  },
];

export {
  auditStatus,
  sortingColumns,
  FacilityAuditTableFilter,
  OrganizationAuditTableFilter,
};
