import { useState, useEffect, useRef, useCallback } from "react";

import { get } from "utils/DeApi";

const useFetchMLNotesTopicValidation = ({
  observationId = "default",
  fetchByDefault = true,
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [validateNotesTopic, setValidateNotesTopic] = useState(null);

  const fetchNotesTopicValidation = useCallback(() => {
    if (observationId === "default") return;
    setIsLoading(true);

    const validateNotesTopicPromise = get(
      `ml/observations/${observationId}/compliance/validate`
    );
    validateNotesTopicPromise.promise
      .then((response) => {
        if (!validateNotesTopicPromise.isCanceled) {
          setValidateNotesTopic(response);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(true);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(validateNotesTopicPromise);
  }, [observationId]);

  useEffect(() => {
    if (fetchByDefault) fetchNotesTopicValidation();

    return () => {
      subscribedPromises.current.forEach(
        (promise) => promise.cancel && promise.cancel()
      );
      subscribedPromises.current = [];
    };
  }, [fetchNotesTopicValidation, fetchByDefault]);

  return {
    error,
    isLoading,
    validateNotesTopic,
    fetchNotesTopicValidation,
  };
};

export default useFetchMLNotesTopicValidation;
