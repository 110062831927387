import { useCallback, useEffect, useRef, useState } from "react";

import { post } from "utils/DeApi";
import { useExtractMLObservationData } from "../hooks";

const useFetchMLConformityLevelValidation = ({
  observationId = "default",
  fetchByDefault = true,
}) => {
  const { notesValue, conformityLevel, validCategory, isFullyAnswered } =
    useExtractMLObservationData(observationId);

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [conformityLevelMLValidation, setConformityLevelMLValidation] =
    useState(null);

  const fetchMLConformityLevelValidation = useCallback(() => {
    if (
      observationId === "default" ||
      !notesValue ||
      !conformityLevel ||
      !validCategory ||
      !isFullyAnswered
    )
      return;

    setIsLoading(true);
    setError(false);

    const payload = {
      text: notesValue,
      conformityLevel,
      category: validCategory,
    };

    const conformityLevelPromise = post(
      "ml/responses/conformity-levels/validate",
      payload
    );

    conformityLevelPromise.promise
      .then((response) => {
        if (!conformityLevelPromise.isCanceled) {
          setConformityLevelMLValidation(response);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(true);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(conformityLevelPromise);
  }, [notesValue, conformityLevel, validCategory, observationId]);

  useEffect(() => {
    if (fetchByDefault) fetchMLConformityLevelValidation();
    return () => {
      subscribedPromises.current.forEach(
        (promise) => promise.cancel && promise.cancel()
      );
      subscribedPromises.current = [];
    };
  }, [fetchMLConformityLevelValidation, fetchByDefault]);

  return {
    error,
    isLoading,
    conformityLevel,
    conformityLevelMLValidation,
    fetchMLConformityLevelValidation,
  };
};

export default useFetchMLConformityLevelValidation;
