import moment from "moment";

export function formatDatetimeToMSQL(date) {
  if (!date) return "";
  if (!moment(date).isValid()) return "";
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}
export function formatDateTimeToLocaleString(str) {
  return moment(str, moment.ISO_8601, true).isValid();
}
