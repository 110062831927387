import React, { useState } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Form, Spinner } from "react-bootstrap";
import ErrorHandler from "components/ui/ErrorHandler";
import { useRoles } from "hooks";
import { useDeleteFacilityAuditGroup } from "features/entity/services";

const GroupDelete = ({ group, onGroupDeleted }) => {
  const { isCertifier } = useRoles();
  const [show, setShow] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { error, isLoading, deleteFacilityAuditGroup } =
    useDeleteFacilityAuditGroup({
      groupId: group?.id,
      onGroupDeleted: () => {
        handleClose();
        onGroupDeleted(group);
      },
    });

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        size="sm"
        className="ms-2"
        onClick={handleShow}
        variant="outline-danger"
      >
        <span translate="no" className="material-symbols-outlined md-16">
          delete
        </span>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="mt-2">
          <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
            <span className="material-symbols-outlined md-24">warning</span>
          </div>
          <h2 className="text-center pt-2">Delete Assessment Group</h2>
          <p className="text-center">
            <small translate="no" className="text-muted">
              {group?.title}
            </small>
          </p>
          <div className="mx-4 mt-2">
            <Form.Group>
              <Form.Check
                label={
                  <small>
                    By checking the box, I confirm that I want to delete this
                    Assessment Group. I understand that this cannot be undone.
                  </small>
                }
                onClick={() => {
                  setIsChecked(!isChecked);
                }}
              />
            </Form.Group>
          </div>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            className="px-3"
            variant="outline-secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="sm"
            variant="danger"
            className="px-3"
            onClick={deleteFacilityAuditGroup}
            disabled={!isChecked || isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

GroupDelete.propTypes = {
  group: PropTypes.object.isRequired,
  onGroupDeleted: PropTypes.func.isRequired,
};

export default GroupDelete;
