import { useContext, useEffect, useRef, useState } from "react";
import { post } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useExportData = () => {
  const organization = useContext(OrganizationContext);

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const exportData = (protocolId, assessments) => {
    setError(null);
    setIsLoading(true);

    const downloadObservationPromise = post(
      `/organizations/${organization?.id}/stream-csv`,
      {
        protocol_id: protocolId,
        assessments: assessments,
      }
    );

    const promise = downloadObservationPromise.promise
      .then((resp) => ({ fileName: `${organization?.name}.csv`, csv: resp }))
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(downloadObservationPromise);
    return promise;
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    exportData,
  };
};

export default useExportData;
